import { debounce as _debounce } from "lodash";
import {
  ACESFilmicToneMapping,
  Scene,
  sRGBEncoding,
  WebGLRenderer,
} from "three";

import ticker from "./util/Ticker";

export default class WebGLViewBasic {
  private canvas: HTMLCanvasElement;
  public renderer: WebGLRenderer;
  public scene?: Scene;

  get context(): WebGLRenderingContext {
    return this.renderer.getContext();
  }

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;

    this.createRenderer();
    this.onResize = this.onResize.bind(this);
    this.onResize();

    this.animate = this.animate.bind(this);
    this.update = this.update.bind(this);
    this.render = this.render.bind(this);
    this.onResize = _debounce(this.onResize, 222);
  }

  private resizeRenderer(width: number, height: number) {
    const maxPixelAspectRadio = 2;
    this.renderer.setPixelRatio(
      Math.min(window.devicePixelRatio, maxPixelAspectRadio)
    );
    this.renderer.setSize(width, height);
  }

  private onResize() {
    this.renderer.domElement.style.width = "";
    this.renderer.domElement.style.height = "";
    this.resizeRenderer(
      this.renderer.domElement.clientWidth,
      this.renderer.domElement.clientHeight
    );
    this.scene?.onResize(
      this.renderer.domElement.clientWidth,
      this.renderer.domElement.clientHeight
    );
  }

  public init(): void {
    this.onResize();
    this.onResize.flush();
    window.addEventListener("resize", this.onResize);
  }

  private createRenderer(): void {
    let context;

    if (typeof WebGL2RenderingContext !== "undefined") {
      context = this.canvas.getContext("webgl2", { antialias: false });
    } else {
      context = this.canvas.getContext("webgl", { antialias: false });
    }
    this.renderer = new WebGLRenderer({
      canvas: this.canvas,
      context,
      antialias: false,
      precision: "mediump",
      powerPreference: "high-performance",
      alpha: false,
    });
    this.renderer.setClearAlpha(0);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = sRGBEncoding;

    (window as any).renderer = this.renderer;
  }

  public pause(): void {
    ticker.removeCallback(this.animate);
  }

  public unpause(): void {
    ticker.addCallback(this.animate);
  }

  public setScene(scene: any | null): void {
    this.scene = scene;

    if (this.scene) {
      this.onResize();
      this.onResize.flush();
      ticker.addCallback(this.animate);
    } else {
      ticker.removeCallback(this.animate);
    }
  }

  private animate(delta: number) {
    this.update(delta);
    this.render();
  }

  private update(correction = 1) {
    this.scene?.update(correction);
  }

  private render() {
    if (!this.renderer) return;
    if (!this.scene) return;

    this.renderer.render(this.scene, this.scene.camera);
  }

  public dispose(): void {
    window.removeEventListener("resize", this.onResize);
    ticker.removeCallback(this.animate);
  }
}

import {
  // AmbientLight,
  DirectionalLight,
  // DirectionalLightHelper,
  Object3D,
  Vector3,
} from "three";
// import { searchParams } from "..";
// import { gui } from "../App";
// import { polarSphericalToCathesian } from "../util/maths";

// const TMP_V3 = new Vector3();

// const positions = {
//   backLight: { radius: 30, theta: -2.6, phi: 0.7 },
//   keyLight: { radius: 30, theta: -1.8, phi: 4.7 },
//   fillLight: { radius: 30, theta: 0.3, phi: 5.2 },
// };
// const colours = {
//   backLight: "#ffffff",
//   keyLight: "#ffffff",
//   fillLight: "#ffffff",
// };
// let backLightFolder, keyLightFolder, fillLightFolder;

class LightingRig extends Object3D {
  private backLight = new DirectionalLight(0xffffff, 2);
  private keyLight = new DirectionalLight(0xffffff, 2.5);
  private fillLight = new DirectionalLight(0xffffff, 0.4);

  constructor() {
    super();
    this.add(this.backLight);
    this.add(this.keyLight);
    this.add(this.fillLight);

    this.backLight.position.set(1.97, -0.433, -1.223);
    this.keyLight.position.set(-1.482, 3.684, -0.873);
    this.fillLight.position.set(2.031, -0.794, 3.352);
    // // this.add(new AmbientLight(0xffffff, 0.3));

    // this.setPositions = this.setPositions.bind(this);
    // this.backLight.target = this;
    // this.keyLight.target = this;
    // this.fillLight.target = this;

    // if (searchParams.has("lighting")) {
    //   this.add(new DirectionalLightHelper(this.backLight, 10, 0xff0000));
    //   this.add(new DirectionalLightHelper(this.keyLight, 5, 0x00ff00));
    //   this.add(new DirectionalLightHelper(this.fillLight, 5, 0x0000ff));

    //   if (searchParams.has("is-dev") && !backLightFolder) {
    //     backLightFolder = gui.addFolder("Back Light");
    //     backLightFolder
    //       .add(positions.backLight, "radius", 6, 50, 1)
    //       .onChange(this.setPositions);
    //     backLightFolder
    //       .add(positions.backLight, "theta", -Math.PI, Math.PI, 0.1)
    //       .onChange(this.setPositions);
    //     backLightFolder
    //       .add(positions.backLight, "phi", 0, Math.PI * 2, 0.1)
    //       .onChange(this.setPositions);
    //     backLightFolder.add(this.backLight, "intensity", 0, 10, 0.1);
    //     backLightFolder
    //       .addColor(colours, "backLight")
    //       .onChange(this.setPositions);

    //     keyLightFolder = gui.addFolder("Key Light");
    //     keyLightFolder
    //       .add(positions.keyLight, "radius", 6, 50, 1)
    //       .onChange(this.setPositions);
    //     keyLightFolder
    //       .add(positions.keyLight, "theta", -Math.PI, Math.PI, 0.1)
    //       .onChange(this.setPositions);
    //     keyLightFolder
    //       .add(positions.keyLight, "phi", 0, Math.PI * 2, 0.1)
    //       .onChange(this.setPositions);
    //     keyLightFolder.add(this.keyLight, "intensity", 0, 10, 0.1);
    //     keyLightFolder
    //       .addColor(colours, "keyLight")
    //       .onChange(this.setPositions);

    //     fillLightFolder = gui.addFolder("Fill Light");
    //     fillLightFolder
    //       .add(positions.fillLight, "radius", 6, 50, 1)
    //       .onChange(this.setPositions);
    //     fillLightFolder
    //       .add(positions.fillLight, "theta", -Math.PI, Math.PI, 0.1)
    //       .onChange(this.setPositions);
    //     fillLightFolder
    //       .add(positions.fillLight, "phi", 0, Math.PI * 2, 0.1)
    //       .onChange(this.setPositions);
    //     fillLightFolder.add(this.fillLight, "intensity", 0, 10, 0.1);
    //     fillLightFolder
    //       .addColor(colours, "fillLight")
    //       .onChange(this.setPositions);
    //   }
    // }

    // this.setPositions();
  }

  // private setPositions() {
  //   polarSphericalToCathesian(
  //     TMP_V3.set(
  //       positions.backLight.radius,
  //       positions.backLight.theta,
  //       positions.backLight.phi
  //     ),
  //     this.backLight.position
  //   );

  //   this.backLight.lookAt(this.position);
  //   this.backLight.color.set(colours.backLight);

  //   polarSphericalToCathesian(
  //     TMP_V3.set(
  //       positions.keyLight.radius,
  //       positions.keyLight.theta,
  //       positions.keyLight.phi
  //     ),
  //     this.keyLight.position
  //   );

  //   this.keyLight.lookAt(this.position);
  //   this.keyLight.color.set(colours.keyLight);

  //   polarSphericalToCathesian(
  //     TMP_V3.set(
  //       positions.fillLight.radius,
  //       positions.fillLight.theta,
  //       positions.fillLight.phi
  //     ),
  //     this.fillLight.position
  //   );

  //   this.fillLight.lookAt(this.position);
  //   this.fillLight.color.set(colours.fillLight);
  // }
}

export default LightingRig;

import * as modelSrcs from "url:../models/with-material/*/scene.glb";

import { FlowState, ResourceType, Sizes } from "../contexts/types";

export const SoundtrackSrcs = new Map([
  [FlowState.Idle, "Phase1-character_selector"],
  [FlowState.ChooseCharacter, "Phase1-character_selector"],
  [FlowState.Build, "Phase2-in_the_planet"],
  [FlowState.Review, "Phase3-the_solidarity_chain"],
  [FlowState.Finish, "Phase4-the_ending_screen"],
]);

export const CharacterNamesDict = new Map([
  [
    ResourceType.Bird,
    [
      { name: "An Artic Tern" },
      { name: "A Flamingo" },
      { name: "A Black Necked Crane" },
      { name: "An Asian Emerald Cuckoo" },
      { name: "A Vulture" },
      { name: "A Cassowary" },
      { name: "A Pink Footed Goose" },
      { name: "A Sociable Weaver" },
      { name: "An Asian Emerald Cuckoo" },
      { name: "A Golden Oriole" },
      { name: "A Wren" },
      { name: "A Puffin" },
      { name: "A Nicobar Pigeon" },
      { name: "A Red-Billed Franklin" },
      { name: "An Alpine Swift" },
      { name: "A Swallow-Tailed Hummingbird" },
      { name: "A Raven" },
      { name: "A Vermilion Flycatcher" },
      { name: "An African Pitta" },
      { name: "An Ostrich" },
      { name: "A Spotted Whistling Duck" },
      { name: "A Teal" },
      { name: "A Turkey" },
      { name: "A Quail" },
      { name: "A Grebe" },
      { name: "A Great Blue Turaco" },
      { name: "A Nightjar" },
      { name: "An Oystercatcher" },
      { name: "A Lapwing" },
      { name: "A Persian Shearwater" },
      { name: "A New Guinea Eagle" },
    ],
  ],
  [
    ResourceType.Fungi,
    [
      { name: "A Toadstool" },
      {
        name: "Penicillium Fungi, known as Penicillin",
        nameShort: "Penicillium Fungi",
      },
      {
        name: "Tuber Melanosporum, often known as Black Truffle ",
        nameShort: "Tuber Melanosporum",
      },
      {
        name: "Pestalotiopsis Microspora, a plastic eating fungus",
        nameShort: "Pestalotiopsis Microspora",
      },
      {
        name: "Candida Albicans, known as human gut microbiota",
        nameShort: "Candida Albicans",
      },
      {
        name: "Mycorrhizal Fungi, which care for trees",
        nameShort: "Mycorrhizal Fungi",
      },
      { name: "Trichoderma, a biopest control", nameShort: "Trichoderma" },
      {
        name: "Basidiomycetes, which decomposes organic matter",
        nameShort: "Basidiomycetes",
      },
      {
        name: "Funarbuscular Mycorrhizal Fungi, which improve soil healthgi",
        nameShort: "Arbuscular Mycorrhizal Fungi",
      },
      { name: "Saccharomyces, which brews beer", nameShort: "Saccharomyces" },
      {
        name: "Tremella Fuciformis, a traditional Chinese medicine",
        nameShort: "Tremella Fuciformis",
      },
      {
        name: "Trichoderma Reesei, which mends concrete",
        nameShort: "Trichoderma Reesei",
      },
      {
        name: "Phylum Chytridiomycota, which lives in snow",
        nameShort: "Phylum Chytridiomycota",
      },
      {
        name: "Pleurotus Ostreatus, which cleans water",
        nameShort: "Pleurotus Ostreatus",
      },
      {
        name: "Penicillium Roqueforti, which makes green cheese",
        nameShort: "Penicillium Roqueforti",
      },
      { name: "A Puffball" },
      { name: "Bracket Fungi" },
      {
        name: "Tolypocladium Inflatum, which enables organ transplants",
        nameShort: "Tolypocladium Inflatum",
      },
      {
        name: "Aspergillus Terreus, which lowers cholesterol",
        nameShort: "Aspergillus Terreus",
      },
      { name: "A Shitake Mushroom" },
      {
        name: "Aspergillus Oryzae, used in soy sauce",
        nameShort: "Aspergillus Oryzae ",
      },
      {
        name: "Candida Bombicola, used in household detergents",
        nameShort: "Candida Bombicola",
      },
      {
        name: "Trametes Versicolor, used to create bricks",
        nameShort: "Trametes Versicolor",
      },
      {
        name: "Mycelium, which is a mushroom leather substitute",
        nameShort: "Mycelium Leather",
      },
      {
        name: "a Lion’s Mane Mushroom, with antioxidant properties",
        nameShort: "A Lion’s Mane Mushroom",
      },
      {
        name: "Fusarium Venenatum, used as a meat substitute",
        nameShort: "Fusarium Venenatum",
      },
      { name: "Mycelium, a seafood substitute", nameShort: "Mycelium Seafood" },
      {
        name: "Anaerobic Fungi, essential for animal nutrition",
        nameShort: "Anaerobic Fungi",
      },
    ],
  ],
  [
    ResourceType.Glacier,
    [
      {
        name: "The Scimitar Glacier from Washington State USA",
        nameShort: "The Scimitar Glacier",
      },
      {
        name: "The Apusiaajik Glacier from Greenland",
        nameShort: "The Apusiaajik Glacier",
      },
      {
        name: "The Cayambe Glacier from Ecuador",
        nameShort: "The Cayambe Glacier",
      },
      {
        name: "The San Quintín Glacier from Chile",
        nameShort: "The San Quintín Glacier",
      },
      {
        name: "The Casa Pangue Glacier from Argentina",
        nameShort: "The Casa Pangue Glacier",
      },
      {
        name: "The Puncak Jaya Glacier from Papua New Guinea",
        nameShort: "The Puncak Jaya Glacier",
      },
      {
        name: "The Mueller Glacier from New Zealand",
        nameShort: "The Mueller Glacier",
      },
      {
        name: "The Mueller Glacier from New Zealand",
        nameShort: "The Chenega Glacier",
      },
      {
        name: "The Jostedalsbreen Glacier from Norway",
        nameShort: "The Jostedalsbreen Glacier",
      },
      {
        name: "The Aletsch Glacier from Switzerland",
        nameShort: "The Aletsch Glacier",
      },
      {
        name: "The Baltoro Glacier from Pakistan",
        nameShort: "The Baltoro Glacier",
      },
      {
        name: "The Siachen Glacier from Ladakh",
        nameShort: "The Siachen Glacier",
      },
      {
        name: "The Lambert Glacier from Antarctica",
        nameShort: "The Lambert Glacier",
      },
      {
        name: "The Vatnajökull Glacier from Iceland",
        nameShort: "The Vatnajökull Glacier",
      },
      {
        name: "The Veobreen Glacier from Norway",
        nameShort: "The Veobreen Glacier",
      },
      { name: "The Guyot Glacier from Alaska", nameShort: "The Guyot Glacier" },
      {
        name: "The Fedchenko Glacier from Tajikistan",
        nameShort: "The Fedchenko Glacier",
      },
      {
        name: "The Brüggen Glacier from Chile",
        nameShort: "The Brüggen Glacier",
      },
      {
        name: "The Perito Moreno Glacier from Argentina",
        nameShort: "The Perito Moreno Glacier",
      },
      {
        name: "The Pasterze Glacier from Austria",
        nameShort: "The Pasterze Glacier",
      },
      {
        name: "The Rongbuk Glacier from Tibet",
        nameShort: "The Rongbuk Glacier",
      },
      {
        name: "The Khumbu Glacier from Nepal",
        nameShort: "The Khumbu Glacier",
      },
      {
        name: "The Potanin Glacier from Mongolia",
        nameShort: "The Potanin Glacier",
      },
      {
        name: "The Seller Glacier from Antarctica",
        nameShort: "The Seller Glacier",
      },
      {
        name: "The Hubbard Glacier from Alaska",
        nameShort: "The Hubbard Glacier",
      },
      {
        name: "The Belukha Glacier from Russia",
        nameShort: "The Belukha Glacier",
      },
      {
        name: "The Salmon Glacier from Canada",
        nameShort: "The Salmon Glacier",
      },
      {
        name: "The Quelccaya Ice Cap from Peru",
        nameShort: "The Quelccaya Ice Cap",
      },
      {
        name: "The Quelccaya Ice Cap from Peru",
        nameShort: "The Tasman Glacier",
      },
    ],
  ],
  [
    ResourceType.Mountain,
    [
      { name: "Mount Arat" },
      { name: "Mount Fuji" },
      { name: "Aconcagua" },
      { name: "Table Mountain" },
      { name: "The Matterhorn" },
      { name: "Mount Kenya" },
      { name: "Gonggar Mountain" },
      { name: "Mount Kinabalu" },
      { name: "Kangchenjunga" },
      { name: "Mount Huang" },
      { name: "Mauna Kea" },
      { name: "Denali" },
      { name: "Yerupaja" },
      { name: "Uluru" },
      { name: "Blanca Peak" },
      { name: "Mount Cook" },
      { name: "Monte Fitz Roy" },
      { name: "Mount Kailash" },
      { name: "Mount Assiniboine" },
      { name: "Mount Everest" },
      { name: "Pico Cristóbal Colón" },
      { name: "Mount Logan" },
      { name: "Puncak Jaya" },
      { name: "Chimborazo" },
      { name: "Citlaltépetl" },
      { name: "Mount Erebus" },
      { name: "Mount Chirripó" },
      { name: "Mount Damavand" },
      { name: "Jade Mountain" },
      { name: "Mount Etna" },
      { name: "Mount Kilimanjaro" },
    ],
  ],
  [
    ResourceType.Human,
    [
      { name: "A Navajo Leader" },
      { name: "A London Hipster" },
      { name: "A New York Creative" },
      { name: "A Peruvian Chef" },
      { name: "A Creole Songwriter" },
      { name: "A Japanese Poet " },
      { name: "A Sydney Property Developer" },
      { name: "A Melanesian Canoe Maker" },
      { name: "A Brazilian Advocate" },
      { name: "A Hmong Politician" },
      { name: "A Bratislavan Designer" },
      { name: "A Colombian Banker" },
      { name: "A Kenyan Coffee Grower" },
      { name: "A Shanghai Fashionista" },
      { name: "A Wisconsin Farmer" },
      { name: "A Singaporean Architect" },
      { name: "A Lebanese Oud Player" },
      { name: "A Parisian Bus Driver" },
      { name: "A Chinese Climate Scientist" },
      { name: "A Slovakian Nursery Teacher" },
      { name: "A Costa Rican Forester" },
      { name: "An Ecuadorian Doctor" },
      { name: "A Dutch Pilot" },
      { name: "A Chilean Journalist" },
      { name: "A Syrian Epidemiologist" },
      { name: "An American Water Engineer" },
      { name: "An Aboriginal Painter" },
      { name: "An Australian Teacher" },
      { name: "A Barbadian Drone Expert" },
      { name: "An Italian Waiter" },
      { name: "A Polish Data Analyst" },
    ],
  ],
  [
    ResourceType.River,
    [
      { name: "The Ganges" },
      { name: "The Amazon River" },
      { name: "The Limpopo River" },
      { name: "The Rhine" },
      { name: "The Yangtze" },
      { name: "The Hudson" },
      { name: "The Vistula" },
      { name: "The Euphrates" },
      { name: "The Mekong" },
      { name: "The Irrawaddy River" },
      { name: "The Ural" },
      { name: "The Mississippi" },
      { name: "The Nile" },
      { name: "The Whanganui River" },
      { name: "The Vilcabamba" },
      { name: "The Congo River" },
      { name: "The Volta River" },
      { name: "The Zambezi River" },
      { name: "The Rio Grande" },
      { name: "The Orinoco" },
      { name: "The Yukon River" },
      { name: "The Dnieper" },
      { name: "The Tigris" },
      { name: "The St. Lawrence River" },
      { name: "The Xingu River" },
      { name: "The Murray River" },
      { name: "The Sepik River" },
      { name: "The Colorado River" },
      { name: "The Loire" },
    ],
  ],
  [
    ResourceType.Tree,
    [
      { name: "An Artic Beech" },
      { name: "A Larch" },
      { name: "A Teak Tree" },
      { name: "A Sandalwood Tree" },
      { name: "A Mangrove" },
      { name: "A Monkey Puzzle Tree" },
      { name: "A Patagonia Cypress" },
      { name: "A California Redwood" },
      { name: "A Baobab" },
      { name: "A Thorn Tree" },
      { name: "An Olive Tree" },
      { name: "A Rubber Tree" },
      { name: "A Neem Tree" },
      { name: "An Oak" },
      { name: "A Mulberry" },
      { name: "A Tibetan Cherry" },
      { name: "A Juniper" },
      { name: "A Socotra Dragon Tree" },
      { name: "An Aspen" },
      { name: "A Sycamore" },
      { name: "A Walnut Tree" },
      { name: "An Apple Tree" },
      { name: "A Birch" },
      { name: "A Spruce" },
      { name: "An Eucalytpus" },
      { name: "A Beech" },
      { name: "An Acacia" },
      { name: "A Breadfruit Tree" },
      { name: "An Avocado Tree" },
    ],
  ],
]);

export const CharacterAboutTexts = new Map([
  [
    ResourceType.Bird,
    "Birds are mobile pollinators and seed carriers. They are adaptable, dependent on biodiverse environments, sing of their daily pleasures, eat many times their own body weight. They are the canaries of the climate and biodiversity catastrophes.",
  ],
  [
    ResourceType.Fungi,
    "Fungi are friendly. They are great communicators, fit in anywhere and do jobs that no other being wants to do. They can be used to make polysterene, leather and building materials, as well as repair concrete.",
  ],
  [
    ResourceType.Glacier,
    "Glaciers are the backbone of the world; ancient, slow to build and quick to run down. Their break up affects millions. They don’t tolerate exploration well. They like to be left alone to get on their quiet work of body building.",
  ],
  [
    ResourceType.Mountain,
    "Mountains have their magic. They are the abodes of sages and immortals; living beings too in their own right. The Alps, Andes and Himalayas are still growing, giving life to rivers, birds, water, humans and more. They are the perfect location from which to imagine the future.",
  ],
  [
    ResourceType.Human,
    "Humans are clever, but not very wise. They are ubiquitous on the planet; create beautiful music, moving poems and great works of art, but have enormous destructive potential. Their survival is now in question.",
  ],
  [
    ResourceType.River,
    "Rivers are the flow of all life. They find their way to the sea, carry the commerce of humans, manage nutrients for living things and provide the key resource that allows humans to build cities and till fields.",
  ],
  [
    ResourceType.Tree,
    "Trees are an ancient incarnation of the internet. They send messages, pass on resources and collaborate through their root systems. They are the oldest living things on the planet. They provide that most precious of things, shelter.",
  ],
]);

export const CharacterFeatures = new Map([
  [
    ResourceType.Bird,
    { influence: 0.2, productivity: 0.3, resilience: 0.3, ingenuity: 0.4 },
  ],
  [
    ResourceType.Fungi,
    { influence: 0.7, productivity: 0.6, resilience: 0.6, ingenuity: 0.6 },
  ],
  [
    ResourceType.Glacier,
    { influence: 0.5, productivity: 0.2, resilience: 0.1, ingenuity: 0.1 },
  ],
  [
    ResourceType.Mountain,
    { influence: 0.5, productivity: 0.3, resilience: 0.6, ingenuity: 0.2 },
  ],
  [
    ResourceType.Human,
    { influence: 0.7, productivity: 0.4, resilience: 0.2, ingenuity: 0.6 },
  ],
  [
    ResourceType.River,
    { influence: 0.6, productivity: 0.4, resilience: 0.2, ingenuity: 0.2 },
  ],
  [
    ResourceType.Tree,
    { influence: 0.4, productivity: 0.5, resilience: 0.6, ingenuity: 0.6 },
  ],
]);

export const ResourceChainBalances = new Map<
  ResourceType,
  { type: ResourceType; quantity: number }[]
>([
  [
    ResourceType.Bird,
    [
      { type: ResourceType.Bird, quantity: 10 },
      { type: ResourceType.Mountain, quantity: 1 },
      { type: ResourceType.Fungi, quantity: 10 },
      { type: ResourceType.Glacier, quantity: 1 },
      { type: ResourceType.Human, quantity: 2 },
      { type: ResourceType.River, quantity: 8 },
      { type: ResourceType.Tree, quantity: 10 },
    ],
  ],
  [
    ResourceType.Fungi,
    [
      { type: ResourceType.Fungi, quantity: 10 },
      { type: ResourceType.Bird, quantity: 1 },
      { type: ResourceType.Tree, quantity: 8 },
      { type: ResourceType.Mountain, quantity: 6 },
      { type: ResourceType.Glacier, quantity: 4 },
      { type: ResourceType.Human, quantity: 2 },
      { type: ResourceType.River, quantity: 4 },
    ],
  ],
  [
    ResourceType.Glacier,
    [
      { type: ResourceType.Glacier, quantity: 10 },
      { type: ResourceType.Bird, quantity: 1 },
      { type: ResourceType.Fungi, quantity: 4 },
      { type: ResourceType.Mountain, quantity: 10 },
      { type: ResourceType.Human, quantity: 1 },
      { type: ResourceType.River, quantity: 8 },
      { type: ResourceType.Tree, quantity: 5 },
    ],
  ],
  [
    ResourceType.Human,
    [
      { type: ResourceType.Bird, quantity: 3 },
      { type: ResourceType.Fungi, quantity: 10 },
      { type: ResourceType.Glacier, quantity: 8 },
      { type: ResourceType.Human, quantity: 8 },
      { type: ResourceType.River, quantity: 10 },
      { type: ResourceType.Tree, quantity: 10 },
      { type: ResourceType.Mountain, quantity: 8 },
    ],
  ],
  [
    ResourceType.Mountain,
    [
      { type: ResourceType.Mountain, quantity: 10 },
      { type: ResourceType.Bird, quantity: 1 },
      { type: ResourceType.Fungi, quantity: 6 },
      { type: ResourceType.Tree, quantity: 7 },
      { type: ResourceType.Glacier, quantity: 7 },
      { type: ResourceType.Human, quantity: 2 },
      { type: ResourceType.River, quantity: 7 },
    ],
  ],
  [
    ResourceType.River,
    [
      { type: ResourceType.River, quantity: 10 },
      { type: ResourceType.Bird, quantity: 2 },
      { type: ResourceType.Fungi, quantity: 4 },
      { type: ResourceType.Mountain, quantity: 9 },
      { type: ResourceType.Glacier, quantity: 6 },
      { type: ResourceType.Human, quantity: 2 },
      { type: ResourceType.Tree, quantity: 8 },
    ],
  ],
  [
    ResourceType.Tree,
    [
      { type: ResourceType.Tree, quantity: 10 },
      { type: ResourceType.Bird, quantity: 2 },
      { type: ResourceType.Fungi, quantity: 8 },
      { type: ResourceType.Mountain, quantity: 6 },
      { type: ResourceType.Glacier, quantity: 6 },
      { type: ResourceType.Human, quantity: 3 },
      { type: ResourceType.River, quantity: 10 },
    ],
  ],
]);

export const MARGIN_OF_ERROR = 0.3;

export const getModelMapKey = (type: ResourceType, quantity: number) => {
  return `resource-type-${type}_quantity-${quantity}`;
};
export const modelMapSrcs = new Map([
  [getModelMapKey(ResourceType.Bird, Sizes.S), modelSrcs["bird-1"]],
  [getModelMapKey(ResourceType.Bird, Sizes.M), modelSrcs["bird-5"]],
  [getModelMapKey(ResourceType.Bird, Sizes.L), modelSrcs["bird-15"]],
  [getModelMapKey(ResourceType.Fungi, Sizes.S), modelSrcs["fungi-1"]],
  [getModelMapKey(ResourceType.Fungi, Sizes.M), modelSrcs["fungi-5"]],
  [getModelMapKey(ResourceType.Fungi, Sizes.L), modelSrcs["fungi-15"]],
  [getModelMapKey(ResourceType.Glacier, Sizes.S), modelSrcs["glacier-1"]],
  [getModelMapKey(ResourceType.Glacier, Sizes.M), modelSrcs["glacier-5"]],
  [getModelMapKey(ResourceType.Glacier, Sizes.L), modelSrcs["glacier-15"]],
  [getModelMapKey(ResourceType.Human, Sizes.S), modelSrcs["human-1"]],
  [getModelMapKey(ResourceType.Human, Sizes.M), modelSrcs["human-5"]],
  [getModelMapKey(ResourceType.Human, Sizes.L), modelSrcs["human-15"]],
  [getModelMapKey(ResourceType.River, Sizes.S), modelSrcs["river-5"]],
  [getModelMapKey(ResourceType.River, Sizes.M), modelSrcs["river-5"]],
  [getModelMapKey(ResourceType.River, Sizes.L), modelSrcs["river-5"]],
  [getModelMapKey(ResourceType.Tree, Sizes.S), modelSrcs["tree-1"]],
  [getModelMapKey(ResourceType.Tree, Sizes.M), modelSrcs["tree-5"]],
  [getModelMapKey(ResourceType.Tree, Sizes.L), modelSrcs["tree-15"]],
  [getModelMapKey(ResourceType.Mountain, Sizes.S), modelSrcs["mountain-1"]],
  [getModelMapKey(ResourceType.Mountain, Sizes.M), modelSrcs["mountain-5"]],
  [getModelMapKey(ResourceType.Mountain, Sizes.L), modelSrcs["mountain-15"]],
]);

export const CHARACTER_ORDER = [
  ResourceType.Human,
  ResourceType.Bird,
  ResourceType.Fungi,
  ResourceType.Glacier,
  ResourceType.River,
  ResourceType.Tree,
  ResourceType.Mountain,
];

export const INTD_PROVOCATIONS: { text: string; types: ResourceType[] }[] = [
  {
    text: "109 fungal strains live in the Svalbard glaciers.",
    types: [ResourceType.Glacier, ResourceType.River],
  },
  {
    text: "90% of plants depend on mycorrhizal fungi for improving water and nutrient absorption.",
    types: [ResourceType.Tree, ResourceType.Fungi],
  },
  {
    text: "A ten minute shower uses 100 litres of water.",
    types: [ResourceType.Human, ResourceType.Glacier, ResourceType.River],
  },
  {
    text: "Be aware that you are not alone. You share your body with many microorganisms, including fungi.",
    types: [ResourceType.Human, ResourceType.Fungi],
  },
  {
    text: "Birds have preferences for different tree species.",
    types: [ResourceType.Tree, ResourceType.Bird],
  },
  {
    text: "Birds of prey get most of the water they need from eating their prey.",
    types: [ResourceType.River, ResourceType.Bird],
  },
  {
    text: "Fungi breakdown leaf litter to release nutrients in rivers.",
    types: [ResourceType.River, ResourceType.Fungi],
  },
  {
    text: "Horse's hoof fungus is growing on this birch tree.",
    types: [ResourceType.Fungi, ResourceType.Tree],
  },
  {
    text: "In Bolivia, Peru, Chile, Argentina, tens of thousands of poor rural dwellers rely on glacier-fed river water for irrigation and drinking water.",
    types: [ResourceType.Glacier, ResourceType.Human],
  },

  {
    text: "Planting trees stops rivers from flooding.",
    types: [ResourceType.Tree, ResourceType.River],
  },
  {
    text: "The glacier- and snow-fed river basins of the Hindu Kush Himalaya mountains provide water to 1.9 billion people in Asia.",
    types: [ResourceType.Glacier, ResourceType.Human],
  },
  {
    text: "The white-winged diuca finch lives on glaciers in Peru.",
    types: [ResourceType.Glacier, ResourceType.Bird],
  },
  {
    text: "There are 40 different types of fungi in human toenails.",
    types: [ResourceType.Fungi, ResourceType.Human],
  },
  {
    text: "This tree is full of beetles, worms and caterpillars.",
    types: [ResourceType.Tree, ResourceType.Bird],
  },
  {
    text: "This tree is sharing water and micronutrients with other trees.",
    types: [ResourceType.Tree, ResourceType.River],
  },
  {
    text: "Zebra finches can 'drink' water from their own fat.",
    types: [ResourceType.Bird, ResourceType.River],
  },
  {
    text: "Mountains make up one-fifth of the world's landscape.",
    types: [ResourceType.Mountain],
  },
  {
    text: "One mountain is sacred in Hinduism, Jainism and Buddhism.",
    types: [ResourceType.Mountain, ResourceType.Human],
  },
  {
    text: "The world's only alpine parrot lives in New Zealand.",
    types: [ResourceType.Mountain, ResourceType.Bird],
  },
  {
    text: "A great forest once covered all of tropical Africa.",
    types: [ResourceType.Tree],
  },
  {
    text: "The Rhine rises in the Swiss Alps.",
    types: [ResourceType.River, ResourceType.Mountain],
  },
  {
    text: "The first mountain was formed around 2 billion years ago.",
    types: [ResourceType.Mountain],
  },
  {
    text: "Micro plastics have been found near the summit of Everest.",
    types: [ResourceType.Human, ResourceType.Mountain],
  },
  {
    text: "The Andes is home to around 2,422 species of birds.",
    types: [ResourceType.Bird, ResourceType.Mountain],
  },
  {
    text: "Mountains provide the fresh water needs of more than half of humanity",
    types: [ResourceType.Mountain, ResourceType.Human, ResourceType.River],
  },
  {
    text: "The New Guinea mountains are home to the world's largest copper and gold mine.",
    types: [ResourceType.Mountain, ResourceType.Human],
  },
  {
    text: "Six of the world's most important food crops originated in mountains.",
    types: [ResourceType.Mountain, ResourceType.Human],
  },
  {
    text: "The Hindu Kush Himalaya is home to 1,000 living languages.",
    types: [ResourceType.Mountain, ResourceType.Human],
  },
  {
    text: "Bracket fungus grow horizontally off the side of dead trees.",
    types: [ResourceType.Fungi, ResourceType.Tree],
  },
  {
    text: "The Western witch's hat is a mushroom not many people have eaten.",
    types: [ResourceType.Human, ResourceType.Fungi],
  },
  {
    text: "There are over 900 species of slime molds on the planet.",
    types: [ResourceType.Fungi],
  },
  {
    text: "Slime molds mine bacteria from rotten logs and decomposing soil.",
    types: [ResourceType.Tree, ResourceType.Fungi],
  },
  {
    text: "Trees provide shelter for acquatic animals.",
    types: [ResourceType.Tree, ResourceType.River],
  },
  {
    text: "Trees restore vital habitats for wildlife in rivers.",
    types: [ResourceType.Tree, ResourceType.River],
  },
  {
    text: "The gut microbiome of Himalayan griffons helps them feed on carcasses.",
    types: [ResourceType.Bird, ResourceType.Fungi],
  },
  {
    text: "Humans have been known to feed their parrots chicken.",
    types: [ResourceType.Human, ResourceType.Bird],
  },
  {
    text: "In 2020, there were over 9.2 billion chickens living in the United States.",
    types: [ResourceType.Bird],
  },
  {
    text: "It is estimated that there are 25-30 billion chickens in the world.",
    types: [ResourceType.Bird],
  },
  {
    text: "Trees share water, nutrients and information through a fungal network nicknamed the wood wide web. They communicate with each other.",
    types: [ResourceType.Tree, ResourceType.Fungi],
  },
  {
    text: "Maize roots attract beneficial bacteria to promote healthy growth.",
    types: [ResourceType.Tree, ResourceType.Fungi],
  },
  {
    text: "Mycorrhizal fungi help move water between trees.",
    types: [ResourceType.Tree, ResourceType.Fungi],
  },
];

import {
  AdditiveBlending,
  Euler,
  Group,
  MathUtils,
  Mesh,
  RepeatWrapping,
  SphereBufferGeometry,
  sRGBEncoding,
  TextureLoader,
  Vector3,
} from "three";
import MainScene, {
  SKYBOX_RADIUS,
  SPHERE_RADIUS_MAX,
  SPHERE_RADIUS_MIN,
} from "./MainScene";
import { shuffle } from "lodash";
import SkyShaderMaterial from "./Materials/SkyShaderMaterial/SkyShader";
import ResourceConnection from "./ResourceConnection";
import resourcesData from "../config/resources.json";
import { pointOnFibonacciSphere } from "../util/maths";
import ResourceMesh from "./ResourceMesh";
import { TInventoryItem, TResource } from "../contexts/types";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { getModelMapKey } from "../config/config";
import FocusController from "./FocusController";
import gradientMapSrc from "url:../maps/gradient-map.png";
import FloatyParticlesSprites from "./FloatyParticlesSprites";

const TMP_V3 = new Vector3();

const ACTIVE_POSITION = new Vector3(0, 0, -1);

let wait = false;

class ResourceWorld extends Group {
  private resources = new Group();
  private resourceConnections = new Group(); // connections which have been previously added, plus connections which are being added this user session
  private focusedResourceConnections = new Group(); // connections which have been previously added, plus connections which are being added this user session
  private pendingResourceConnections = new Map<string, ResourceConnection>(); // connections which are being added this user session
  public sky: Mesh;
  private raycastableElements: Mesh[] = [];
  private focusController: FocusController;
  private _focusedResource: ResourceMesh | null = null;
  private _character: ResourceMesh | null = null; // the character is a random resource of the type which the user has chosen to play with.
  private _targetRotation = new Euler().copy(this.rotation);
  public particles = new FloatyParticlesSprites(
    SKYBOX_RADIUS,
    0.75,
    300,
    "sphere"
  );

  constructor(focusController: FocusController) {
    super();

    this.add(this.resources);
    this.add(this.resourceConnections);
    this.add(this.focusedResourceConnections);
    this.focusController = focusController;

    const gradientMap = new TextureLoader().load(gradientMapSrc);
    gradientMap.repeat.x = gradientMap.repeat.y = RepeatWrapping;
    gradientMap.encoding = sRGBEncoding;
    this.sky = new Mesh(
      new SphereBufferGeometry(SKYBOX_RADIUS),
      new SkyShaderMaterial({
        gradientMap,
        gradientMapCount: 8,
        gradientMapIndex: 0,
        hasSun: true,
        blending: AdditiveBlending,
      })
    ).rotateX(Math.PI * 0.5);
    this.add(this.sky);
    this.add(this.particles);
  }

  public addResourceMeshes(models: Map<string, GLTF>): void {
    resourcesData.resources.forEach((data, i) => {
      const resource = new ResourceMesh(
        data,
        models.get(getModelMapKey(data.type, data.quantity))
      );

      this.resources.add(resource);
    });

    this.setResourceMeshPositionsOnWorldSphere();
  }

  private setResourceMeshPositionsOnWorldSphere() {
    shuffle(this.resources.children).forEach((child, i) => {
      const radius =
        SPHERE_RADIUS_MIN +
        MathUtils.randFloatSpread(SPHERE_RADIUS_MAX - SPHERE_RADIUS_MIN);
      const positionOnWorldSphere = pointOnFibonacciSphere(
        i,
        resourcesData.resources.length,
        TMP_V3
      ).multiplyScalar(radius);
      (child as ResourceMesh).positionOnWorldSphere = positionOnWorldSphere;
    });
  }

  public set cachedResourceConnections(
    cachedResourceConnections: Map<string, string[]>
  ) {
    cachedResourceConnections.forEach((outgoingConnections, from) => {
      const fromObject = this.getObjectByName(from) as ResourceMesh;
      if (!fromObject) return;
      fromObject.outgoingConnections = outgoingConnections;

      outgoingConnections.forEach((to) => {
        const toObject = this.getObjectByName(to) as ResourceMesh;
        if (!toObject) return;
        if (toObject.incomingConnections.indexOf(from) === -1) {
          toObject.incomingConnections.push(from);
        }
      });
    });
  }

  public set characterResource(resource: TResource | null) {
    if (resource === null) {
      this.character = null;

      (this.resources.children as ResourceMesh[]).forEach((resource) => {
        resource.visuallyHidden = false;
      });

      this.raycastableElements = [];

      this.pendingResourceConnections.forEach((pendingResourceConnection) => {
        pendingResourceConnection.parent?.remove(pendingResourceConnection);
      });
      this.pendingResourceConnections.clear();
    } else {
      this.setResourceMeshPositionsOnWorldSphere(); // randomise positions
      this.character = this.resources.getObjectByName(
        resource._id
      ) as ResourceMesh;

      this.raycastableElements = (this.resources.children as ResourceMesh[])
        .filter(
          ({ isActiveCharacter, visible }) => !isActiveCharacter && visible
        )
        .map((resourceMesh) => {
          return resourceMesh.hitTestMesh;
        });
    }
  }

  public set character(character: ResourceMesh) {
    if (this.character) {
      this.character.summonedPosition = null;
      this.character.isActiveCharacter = false;
    }
    this._character = character;
    if (this.character) {
      this.character.summonedPosition = ACTIVE_POSITION;
      this.character.isActiveCharacter = true;
    }
  }

  public get character(): ResourceMesh | null {
    return this._character;
  }

  public set inventory(inventory: TInventoryItem[]) {
    (this.resources.children as ResourceMesh[]).forEach((resource) => {
      resource.isInInventory = !!inventory.find(
        ({ _id }) => _id === resource.data._id
      );
    });
  }

  public drawConnectionsForInventory(
    inventory: TInventoryItem[],
    closeChain = false
  ) {
    if (inventory === null || this.character === null) return;

    const drawConnection = (fromName: string, toName: string) => {
      if (!this.character) return;
      const connectionName = `${fromName}_${toName}`;

      // if there's already a connection for these resources from previous sessions, we don't need to create a new one
      if (this.resourceConnections.getObjectByName(connectionName)) {
        return;
      }
      if (!this.pendingResourceConnections.has(connectionName)) {
        this.pendingResourceConnections.set(
          connectionName,
          new ResourceConnection(
            this.getObjectByName(fromName) as ResourceMesh,
            this.getObjectByName(toName) as ResourceMesh,
            true
          )
        );
      }
      this.resourceConnections.add(
        this.pendingResourceConnections.get(connectionName)
      );
    };

    requestAnimationFrame(() => {
      // remove all pending for ease...
      this.pendingResourceConnections.forEach((resourceConnection) => {
        resourceConnection.parent?.remove(resourceConnection);
      });

      if (inventory.length > 0 && this.character) {
        const fromName = this.character.data._id;
        const toName = inventory[0]._id;
        drawConnection(fromName, toName);
      }

      for (let i = 0; i < inventory.length; i++) {
        // last item in inventory doesn't have a connection yet
        if (i === inventory.length - 1) {
          continue;
        }

        const fromName = inventory[i]._id;
        const toName = inventory[i + 1]._id;

        drawConnection(fromName, toName);
      }

      if (closeChain) {
        const fromName = inventory[inventory.length - 1]._id;
        const toName = this.character.data._id;

        drawConnection(fromName, toName);
      }
    });
  }

  public drawConnectionsForFocusedObject(object: ResourceMesh) {
    const outgoingConnections = [...object.outgoingConnections].slice(0, 6);
    outgoingConnections.forEach((toName) => {
      this.focusedResourceConnections.add(
        new ResourceConnection(
          object,
          this.getObjectByName(toName) as ResourceMesh,
          false
        )
      );
    });

    const incomingConnections = [...object.incomingConnections].slice(0, 6);
    incomingConnections.forEach((fromName) => {
      this.focusedResourceConnections.add(
        new ResourceConnection(
          this.getObjectByName(fromName) as ResourceMesh,
          object,
          false
        )
      );
    });
  }

  public clearConnectionsForFocusedObject() {
    for (
      let i = this.focusedResourceConnections.children.length - 1;
      i >= 0;
      i--
    ) {
      this.focusedResourceConnections.remove(
        this.focusedResourceConnections.children[i]
      );
    }
  }

  public summonChain(inventory: TInventoryItem[]): number {
    const chain = [this.character, ...inventory];
    this.resources.children.forEach((child) => {
      if (inventory.find(({ _id }) => child.name === _id)) return;
      if (this.character === child) return;
      (child as ResourceMesh).summonedPosition = null;
      (child as ResourceMesh).visuallyHidden = true;
    });

    const gap = (Math.PI * 2) / chain.length;
    const circumfrence = chain.length * 1.33;
    const radius = circumfrence / Math.PI / 2;
    chain.forEach((item, i) => {
      const theta = i * gap;
      const x = Math.cos(theta) * radius * -1;
      const y = Math.sin(theta) * radius;
      const z = 0;
      TMP_V3.set(x, y, z);

      if (item instanceof ResourceMesh) {
        item.visuallyHidden = false;
        item.chainReviewIndex = i;
        item.summonedPosition = TMP_V3;
      } else {
        const object = this.getObjectByName(item._id) as ResourceMesh;
        object.visuallyHidden = false;
        object.chainReviewIndex = i;
        object.summonedPosition = TMP_V3;
      }
    });

    return radius;
  }

  public unsummonChain() {
    this.resources.children.forEach((child) => {
      (child as ResourceMesh).chainReviewIndex = null;
      (child as ResourceMesh).summonedPosition = null;
    });
  }

  // TODO — create a new group for each focused object and remove that group.
  public set focusedResource(object: ResourceMesh | null) {
    if (object === this._focusedResource) {
      return;
    }
    this._focusedResource = object;

    requestIdleCallback(
      () => {
        this.clearConnectionsForFocusedObject();
        if (object) {
          this.drawConnectionsForFocusedObject(object);
        }
      },
      { timeout: 111 }
    );
  }

  public incrementRotation(x, y, z) {
    this.rotation.x += x;
    this.rotation.y += y;
    this.rotation.x += z;

    this._targetRotation.x += x;
    this._targetRotation.y += y;
    this._targetRotation.x += z;
  }

  public set targetRotation({ x, y, z }) {
    this._targetRotation.set(x, y, z);

    this.rotation.y = this.rotation.y % (Math.PI * 2);
    this._targetRotation.y = this.targetRotation.y % (Math.PI * 2);
  }

  public get targetRotation() {
    return this._targetRotation;
  }

  public set visuallyHidden(isHidden) {
    this.resources.children.forEach((child) => {
      (child as ResourceMesh).visuallyHidden = isHidden;
    });
  }

  public update(correction = 1) {
    this.resources.children.forEach((resource) => {
      (resource as ResourceMesh).update(correction);
    });

    if (!this.visible) return;
    this.particles.update(correction);
    this.sky.material.update(correction);
    this.resourceConnections.children.forEach((connection) => {
      (connection as ResourceConnection).update(correction);
    });
    this.focusedResourceConnections.children.forEach((connection) => {
      (connection as ResourceConnection).update(correction);
    });

    this.rotation.x += (this.targetRotation.x - this.rotation.x) * 0.005;
    this.rotation.y += (this.targetRotation.y - this.rotation.y) * 0.005;
    this.rotation.z += (this.targetRotation.z - this.rotation.z) * 0.005;

    this.rotation.x = MathUtils.clamp(
      this.rotation.x,
      -Math.PI * 0.425,
      Math.PI * 0.425
    );

    const focusedObject = this.focusController.checkFocus(
      this.raycastableElements
    );
    if (focusedObject) {
      this.focusedResource = focusedObject as ResourceMesh;
    } else {
      this.focusedResource = null;
    }
  }
}

export default ResourceWorld;

import {
  AdditiveBlending,
  Box3,
  BoxBufferGeometry,
  Frustum,
  Group,
  Material,
  MathUtils,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PerspectiveCamera,
  SphereBufferGeometry,
  Sprite,
  SpriteMaterial,
  TextureLoader,
  Vector2,
  Vector3,
} from "three";
import SimplexNoise from "simplex-noise";
import { TResource } from "../contexts/types";
import MainScene from "./MainScene";
import { getCharacterName } from "../util/helpers";
import {
  carthesianToPolarSpherical,
  polarSphericalToCathesian,
} from "../util/maths";
import minimapSpriteSrc from "../maps/minimap-sprite.png";
import AnimatedCamera from "./AnimatedCamera";

const V3_ZERO = new Vector3();
const TMP_V3 = new Vector3();

// const WIREFRAME_MATERIAL = new MeshBasicMaterial({
//   color: 0x000000,
//   wireframe: true,
// });

const INVISIBLE_MATERIAL = new MeshBasicMaterial({
  alphaTest: 1.1,
  wireframe: true,
  transparent: true,
});

const SPHERE_GEOM = new SphereBufferGeometry(1);
const minimapSprite = new TextureLoader().load(minimapSpriteSrc);
const MINIMAP_MATERIAL = new SpriteMaterial({
  color: 0xffffff,
  sizeAttenuation: true,
  transparent: true,
  map: minimapSprite,
  blending: AdditiveBlending,
  opacity: 0.4,
  depthWrite: false,
  depthTest: false,
});
const MINIMAP_MATERIAL_SELECTED = new SpriteMaterial({
  color: 0x7ef7e8,
  sizeAttenuation: true,
  transparent: true,
  map: minimapSprite,
  blending: AdditiveBlending,
});

const SELECTED_MATERIAL = new MeshBasicMaterial({
  color: 0x7ef7e8,
  wireframe: true,
});

const simplex = new SimplexNoise();

const SPRING = 0.018;
const DAMPING = 0.85;
const WANDER = 1;

class ResourceMesh extends Group {
  public data: TResource & { characterName: string };
  private screenSpacePosition = new Vector2();
  private worldPosition = new Vector3();
  private frustum = new Frustum();
  private projScreenMatrix = new Matrix4();
  private targetPosition = new Vector3();
  private naturalToTargetPosition = new Vector3();
  private positionVelocity = new Vector3();
  private scaleVelocity = 0;
  private naturalScale = 1;
  private targetScale = 1;
  private currentScale = 1;
  private _positionOnWorldSphere = new Vector3();
  private _currentAtRestPosition = new Vector3();
  private _targetAtRestPosition = new Vector3();
  private isSummoned = false; // is summoned to camera OR chain
  private _chainReviewIndex: number | null = null;
  private _isActiveCharacter = false;
  private _isFocused = false;
  private _isInInventory = false;
  private _isInWorld = true;
  private ignoreBounceScale = false;
  private time = 0;
  private rotationSpeed = MathUtils.randFloatSpread(2);
  public outgoingConnections: string[] = [];
  public incomingConnections: string[] = [];
  public hitTestMesh: Mesh;
  public modelGroup = new Group();
  private minimapMesh = new Sprite(MINIMAP_MATERIAL);

  constructor({ quantity, type, _id }: TResource, model) {
    super();

    this.name = _id;
    this.naturalScale = quantity === 1 ? 1.5 : quantity === 5 ? 2 : 2.5;
    this.targetScale = this.naturalScale;
    this.currentScale = this.targetScale;
    this.matrixAutoUpdate = false;

    this.onChainItemAudio = this.onChainItemAudio.bind(this);

    // const onBeforeRender = () => {
    //   console.log("on before render");
    // };

    model.scene.traverse((child, i) => {
      if (child instanceof Mesh) {
        const meshClone = new Mesh(child.geometry, child.material);
        meshClone.position.copy(child.position);
        meshClone.rotation.copy(child.rotation);
        meshClone.scale.copy(child.scale);
        meshClone.userData.material = child.material;
        this.modelGroup.add(meshClone);
      }
    });
    const bb = new Box3().setFromObject(this.modelGroup);
    const dimensions = TMP_V3.copy(bb.max).sub(bb.min);
    this.layers.enable(1);

    this.minimapMesh.layers.disable(0);
    this.minimapMesh.layers.enable(1);
    this.minimapMesh.scale.set(
      1 / this.naturalScale,
      1 / this.naturalScale,
      1 / this.naturalScale
    );
    this.add(this.minimapMesh);

    // const geom = new SphereBufferGeometry(
    //   Math.max(dimensions.x, dimensions.y, dimensions.z) * 0.5,
    //   6,
    //   4
    // );
    const rad = Math.max(dimensions.x, dimensions.y, dimensions.z) * 0.5;
    this.hitTestMesh = new Mesh(SPHERE_GEOM, INVISIBLE_MATERIAL);
    this.hitTestMesh.scale.set(rad, rad, rad);

    this.add(this.modelGroup);
    this.add(this.hitTestMesh);
    this.updateMatrix();

    this.traverse((child) => {
      if (child.matrixAutoUpdate !== undefined) {
        child.matrixAutoUpdate = false;
        child.updateMatrix();
      }
    });

    const { name, nameShort } = getCharacterName(type);

    this.data = {
      _id,
      quantity,
      type,
      characterName: nameShort || name,
    };
  }

  public set summonedPosition(position: Vector3 | null) {
    if (position) {
      if (!this.isSummoned) {
        this._currentAtRestPosition.copy(this.position);
        this.isSummoned = true;
      }
      this._targetAtRestPosition.copy(position);
      this.targetScale = 1;
    } else {
      this.isSummoned = false;
      this._targetAtRestPosition.copy(this.positionOnWorldSphere);
      this.targetScale = this.naturalScale;
    }
  }

  // public get summonedPosition() {
  //   return this._currentAtRestPosition;
  // }

  public set positionOnWorldSphere(position: Vector3) {
    this.position.copy(position);
    this._positionOnWorldSphere.copy(position);
    this._currentAtRestPosition.copy(position);
    this._targetAtRestPosition.copy(position);
  }

  public get positionOnWorldSphere() {
    return this._positionOnWorldSphere;
  }

  public get connectionsCount() {
    return this.outgoingConnections.length + this.incomingConnections.length;
  }

  public get randomConnectionName() {
    const allConnections = [
      ...this.outgoingConnections,
      ...this.incomingConnections,
    ];
    if (allConnections.length === 0) return null;
    const name =
      allConnections[Math.floor(Math.random() * allConnections.length)];
    const resource = this.parent.getObjectByName(name) as ResourceMesh;
    if (!resource) return null;
    return resource.data.characterName;
  }

  public set isActiveCharacter(isActiveCharacter) {
    this.isFocused = false;
    this._isActiveCharacter = isActiveCharacter;
    if (isActiveCharacter) {
      this.modelGroup.traverse((child) => {
        if (child instanceof Mesh) {
          child.visible = false;
        }
      });
      this.setInChainVisualState(true);
    } else {
      this.modelGroup.traverse((child) => {
        if (child instanceof Mesh) {
          child.visible = true;
        }
      });
      this.setInChainVisualState(false);
    }
  }

  public set visuallyHidden(isHidden) {
    if (isHidden) {
      this.targetScale = 0;
    } else {
      this.targetScale =
        this.isSummoned || this.isInChainReview ? 1 : this.naturalScale;
    }
  }

  public set isInWorld(isIn) {
    this._isInWorld = isIn;
    this.visible = isIn;
  }

  public get isInWorld() {
    return this._isInWorld;
  }

  public get isActiveCharacter() {
    return this._isActiveCharacter;
  }

  public setInChainVisualState(isIn) {
    if (isIn && !this.isInChainReview) {
      this.minimapMesh.material = MINIMAP_MATERIAL_SELECTED;
      this.minimapMesh.scale.set(
        (1 / this.naturalScale) * 2.5,
        (1 / this.naturalScale) * 2.5,
        (1 / this.naturalScale) * 2.5
      );
      this.modelGroup.traverse((child) => {
        if (child instanceof Mesh && child?.material instanceof Material) {
          child.material = SELECTED_MATERIAL;
        }
      });
    } else if (!isIn && (!this.isActiveCharacter || this.isInChainReview)) {
      this.minimapMesh.material = MINIMAP_MATERIAL;
      this.minimapMesh.scale.set(
        (1 / this.naturalScale) * 0.8,
        (1 / this.naturalScale) * 0.8,
        (1 / this.naturalScale) * 0.8
      );
      this.modelGroup.traverse((child) => {
        if (child instanceof Mesh && child?.material instanceof Material) {
          child.material = child.userData.material;
        }
      });
    }
  }

  public doBounceScaleAnimation() {
    if (this.ignoreBounceScale === true) return;

    const previousTargetScale = this.targetScale;
    this.ignoreBounceScale = true;
    this.targetScale = previousTargetScale * 1.33;
    setTimeout(() => {
      this.ignoreBounceScale = false;
      this.targetScale = previousTargetScale;
    }, 444);
  }

  public onChainItemAudio({ detail }) {
    if (detail === this._chainReviewIndex) {
      this.doBounceScaleAnimation();
    }
  }

  public set isInInventory(isIn) {
    if (this._isInInventory === isIn) return;
    this._isInInventory = isIn;
    if (this.isActiveCharacter) return;
    if (isIn) {
      if (!this.isInChainReview && this.isFocused) {
        this.doBounceScaleAnimation();
      }
      this.setInChainVisualState(true);
    } else {
      this.setInChainVisualState(false);
    }
  }

  public get isInInventory() {
    return this._isInInventory;
  }

  public set chainReviewIndex(chainIndex) {
    if (typeof chainIndex === typeof this._chainReviewIndex) {
      this._chainReviewIndex = chainIndex;
      return;
    }
    this._chainReviewIndex = chainIndex;
    if (this.isInChainReview) {
      this.modelGroup.traverse((child) => {
        if (child instanceof Mesh) {
          child.visible = true;
        }
      });

      this.setInChainVisualState(false);

      if (this.isActiveCharacter) {
        this.rotation.set(0, 0, 0);
      }

      window.addEventListener("chain-item-audio", this.onChainItemAudio);
    } else {
      window.removeEventListener("chain-item-audio", this.onChainItemAudio);
    }
  }

  public get isInChainReview() {
    return this._chainReviewIndex !== null;
  }

  public set isFocused(isFocused) {
    this._isFocused = isFocused;
  }

  public get isFocused() {
    return this._isFocused;
  }

  public getScreenSpacePosition(camera: PerspectiveCamera): Vector2 {
    this.getWorldPosition(this.worldPosition);
    this.worldPosition.project(camera);
    this.screenSpacePosition.x =
      ((this.worldPosition.x + 1) / 2) * window.innerWidth;
    this.screenSpacePosition.y =
      (-(this.worldPosition.y - 1) / 2) * window.innerHeight;
    return this.screenSpacePosition;
  }

  public getIsInFrustum(camera: PerspectiveCamera): boolean {
    this.getWorldPosition(this.worldPosition);
    this.projScreenMatrix.multiplyMatrices(
      camera.projectionMatrix,
      camera.matrixWorldInverse
    );
    this.frustum.setFromProjectionMatrix(this.projScreenMatrix);
    return this.frustum.containsPoint(this.worldPosition);
  }

  public getIsInFrontOfCamera(camera: AnimatedCamera): boolean {
    TMP_V3.copy(this.position);
    const dot = this.parent
      .localToWorld(TMP_V3)
      .normalize()
      .dot(camera.forward);
    return dot > 0;
  }

  public calculateTargetPosition() {
    // if i'm not summoned, work out where i should be when i have noise offset taken into account
    TMP_V3.copy(this._currentAtRestPosition);

    if (this.isSummoned) {
      this.parent.worldToLocal(TMP_V3);
    } else {
      carthesianToPolarSpherical(TMP_V3, TMP_V3);
      const noiseR = simplex.noise2D(
        this.positionOnWorldSphere.x * 10,
        this.time * 0.0001
      );
      const noiseTheta = simplex.noise2D(
        this.positionOnWorldSphere.y * 5,
        (this.time + 10) * 0.000002
      );
      const noisePhi = simplex.noise2D(
        this.positionOnWorldSphere.z * 5,
        (this.time + 20) * 0.000002
      );
      TMP_V3.x += noiseR * 2.5;
      TMP_V3.y += noiseTheta * Math.PI * 0.25;
      TMP_V3.z += noisePhi * Math.PI * 0.25;
      polarSphericalToCathesian(TMP_V3, TMP_V3);
    }

    if (this.isFocused) {
      // pull towards gaze
      ((this.parent.parent.parent as MainScene).camera as PerspectiveCamera)
        .getWorldDirection(this.targetPosition)
        .applyMatrix4(this.parent.parent.matrix.invert())
        .multiplyScalar(this.position.length());

      this.naturalToTargetPosition.copy(this.targetPosition).sub(TMP_V3);
      const scalar = 1 - this.naturalToTargetPosition.length() / WANDER / 2;
      this.naturalToTargetPosition
        .multiplyScalar(Math.max(0, scalar))
        .clampLength(0, WANDER);
      this.targetPosition.copy(TMP_V3).add(this.naturalToTargetPosition);
    } else {
      // just copy target
      this.targetPosition.copy(TMP_V3);
    }
  }

  public update(correction = 1): void {
    this.time += 16.66 * correction;

    this._currentAtRestPosition.lerpVectors(
      this._currentAtRestPosition,
      this._targetAtRestPosition,
      0.02 * correction
    );

    this.calculateTargetPosition();

    this.scaleVelocity +=
      (this.targetScale - this.currentScale) * SPRING * correction;
    this.scaleVelocity *= DAMPING;
    this.currentScale += this.scaleVelocity;
    this.scale.set(this.currentScale, this.currentScale, this.currentScale);
    // this.currentScale += (this.targetScale - this.currentScale) * (1 - DAMPING);
    // this.scale.set(this.currentScale, this.currentScale, this.currentScale);

    this.positionVelocity.add(
      TMP_V3.copy(this.targetPosition)
        .sub(this.position)
        .multiplyScalar(SPRING)
        .multiplyScalar(correction)
    );
    this.position.add(this.positionVelocity.multiplyScalar(DAMPING));

    if (this.isActiveCharacter && !this.isInChainReview) {
      this.lookAt(V3_ZERO);
    } else if (this.isFocused) {
      this.rotation.y += 0.075 * Math.sign(this.rotationSpeed) * correction;
    } else {
      this.rotation.y += this.rotationSpeed * 0.007 * correction;
    }

    // this was cuasing bugs

    // const shouldUpdate =
    //   this.isActiveCharacter ||
    //   this.isInChainReview ||
    //   this.getIsInFrontOfCamera(this.parent.parent.parent.camera);
    // if (shouldUpdate) {
    //   this.updateMatrix();
    // }
    this.updateMatrix();
  }
}

export default ResourceMesh;

import { debounce as _debounce } from "lodash";
import {
  ACESFilmicToneMapping,
  CineonToneMapping,
  Color,
  Object3D,
  Scene,
  sRGBEncoding,
  Vector2,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { BokehPass } from "three/examples/jsm/postprocessing/BokehPass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader";

import MainScene from "./MainScene";
import ticker from "./util/Ticker";
import UIScene from "./UIScene";
import { gui } from "../App";

const RENDER_OUTLINES = true;
const PIXEL_RATIO = Math.min(window.devicePixelRatio, 1.75);

const params = {
  exposure: 1,
  bloomStrength: 1.5,
  bloomThreshold: 0,
  bloomRadius: 0,
};

export default class WebGLView {
  private canvas: HTMLCanvasElement;
  public renderer: WebGLRenderer;
  private composer?: EffectComposer;
  private renderPass?: RenderPass;
  private outlinePassYellow?: OutlinePass;
  private outlinePassBlue?: OutlinePass;
  public scene?: MainScene;
  public uiScene = new UIScene();
  public renderMinimap = false;

  get context(): WebGLRenderingContext {
    return this.renderer.getContext();
  }

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;

    this.createRenderer();
    this.onResize = this.onResize.bind(this);
    this.onResize();

    this.animate = this.animate.bind(this);
    this.update = this.update.bind(this);
    this.render = this.render.bind(this);
    this.onResize = _debounce(this.onResize, 222);
  }

  private resizeRenderer(width: number, height: number) {
    this.renderer.setPixelRatio(PIXEL_RATIO);
    this.renderer.setSize(width, height);

    if (this.composer) {
      this.composer.setPixelRatio(PIXEL_RATIO);
      this.composer.setSize(width, height);
    }
    // this.renderer.info.autoReset = false;
  }

  private onResize() {
    this.renderer.domElement.style.width = "";
    this.renderer.domElement.style.height = "";
    this.resizeRenderer(
      this.renderer.domElement.clientWidth,
      this.renderer.domElement.clientHeight
    );
    this.scene?.onResize(
      this.renderer.domElement.clientWidth,
      this.renderer.domElement.clientHeight
    );
  }

  public init(): void {
    this.onResize();
    this.onResize.flush();
    window.addEventListener("resize", this.onResize);
  }

  private createRenderer(): void {
    this.renderer = new WebGLRenderer({
      canvas: this.canvas,
      antialias: false,
      powerPreference: "high-performance",
      // precision: "mediump",
      alpha: false,
    });
    this.renderer.setClearAlpha(0);
    this.renderer.setClearColor(0xffffff);
    // this.renderer.toneMapping = ACESFilmicToneMapping;
    // this.renderer.toneMappingExposure = 1;
    this.renderer.physicallyCorrectLights = true;
    this.renderer.autoClearStencil = true;
    this.renderer.autoClearDepth = true;
    this.renderer.autoClearColor = false;
    if (!RENDER_OUTLINES) {
      this.renderer.outputEncoding = sRGBEncoding;
    }

    (window as any).renderer = this.renderer;

    if (RENDER_OUTLINES) {
      this.composer = new EffectComposer(this.renderer);
      this.composer.renderTarget1.texture.encoding = sRGBEncoding;
      this.renderPass = new RenderPass(null, null);
      this.composer.addPass(this.renderPass);
    }
  }

  public pause(): void {
    ticker.removeCallback(this.animate);
  }

  public unpause(): void {
    ticker.addCallback(this.animate);
  }

  public setScene(scene: MainScene | null): void {
    this.scene = scene;

    if (RENDER_OUTLINES) {
      this.renderPass.scene = this.scene;
      this.renderPass.camera = this.scene?.camera;

      if (this.outlinePassYellow) {
        this.composer.removePass(this.outlinePassYellow);
        this.composer.removePass(this.outlinePassBlue);
      }
      if (this.scene) {
        this.outlinePassYellow = new OutlinePass(
          new Vector2(
            this.renderer.domElement.clientWidth,
            this.renderer.domElement.clientHeight
          ),
          this.scene,
          this.scene.camera
        );
        this.outlinePassYellow.visibleEdgeColor = new Color("#EBFF00");
        this.outlinePassYellow.hiddenEdgeColor = new Color("#EBFF00");
        this.outlinePassYellow.edgeGlow = 0.5;
        this.outlinePassYellow.edgeThickness = 0.5;
        this.outlinePassYellow.edgeStrength = 5;
        this.outlinePassYellow.pulsePeriod = 0;
        this.outlinePassYellow.enabled = false;

        this.outlinePassBlue = new OutlinePass(
          new Vector2(
            this.renderer.domElement.clientWidth,
            this.renderer.domElement.clientHeight
          ),
          this.scene,
          this.scene.camera
        );
        this.outlinePassBlue.visibleEdgeColor = new Color("#7ef7e8");
        this.outlinePassBlue.hiddenEdgeColor = new Color("#7ef7e8");
        this.outlinePassBlue.edgeGlow = 0.5;
        this.outlinePassBlue.edgeThickness = 0.5;
        this.outlinePassBlue.edgeStrength = 5;
        this.outlinePassBlue.pulsePeriod = 0;
        this.outlinePassBlue.enabled = false;

        this.composer.addPass(this.outlinePassYellow);
        // this.composer.addPass(this.outlinePassBlue);
        const gammaShaderPass = new ShaderPass(GammaCorrectionShader);
        this.composer.addPass(gammaShaderPass);
      }
    }

    if (this.scene) {
      this.onResize();
      this.onResize.flush();
      ticker.addCallback(this.animate);
    } else {
      ticker.removeCallback(this.animate);
    }
  }

  private animate(delta: number) {
    this.update(delta);
    this.render();
  }

  private update(correction = 1) {
    this.scene?.update(correction);
  }

  private render() {
    if (!this.renderer) return;
    if (!this.scene) return;
    this.renderer.clear(true, true, true);

    if (RENDER_OUTLINES) {
      this.outlinePassYellow.selectedObjects = this.scene.outlinedObjectsYellow;
      this.outlinePassYellow.enabled =
        !!this.outlinePassYellow.selectedObjects?.length;

      this.outlinePassBlue.selectedObjects = this.scene.outlinedObjectsBlue;
      this.outlinePassBlue.enabled =
        !!this.outlinePassBlue.selectedObjects?.length;
    }

    this.renderer.setViewport(
      0,
      0,
      this.canvas.width / PIXEL_RATIO,
      this.canvas.height / PIXEL_RATIO
    );
    this.renderer.setScissor(
      0,
      0,
      this.canvas.width / PIXEL_RATIO,
      this.canvas.height / PIXEL_RATIO
    );
    this.renderer.setScissorTest(true);

    if (this.renderMinimap) {
      this.renderer.clear();

      if (RENDER_OUTLINES) {
        this.composer.render();
      } else {
        this.renderer.render(this.scene, this.scene.camera);
      }
      this.renderer.setViewport(
        (this.canvas.width * 0.005) / PIXEL_RATIO,
        (this.canvas.width * 0.037) / PIXEL_RATIO,
        (this.canvas.width * 0.115) / PIXEL_RATIO,
        (this.canvas.width * 0.115) / PIXEL_RATIO
      );
      this.renderer.setScissor(
        (this.canvas.width * 0.005) / PIXEL_RATIO,
        (this.canvas.width * 0.037) / PIXEL_RATIO,
        (this.canvas.width * 0.115) / PIXEL_RATIO,
        (this.canvas.width * 0.115) / PIXEL_RATIO
      );
      // this.renderer.clearDepth();
      this.renderer.render(this.uiScene, this.uiScene.camera);
      this.renderer.render(this.scene, this.scene.minimapCamera);
    } else {
      if (RENDER_OUTLINES) {
        this.composer.render();
      } else {
        this.renderer.render(this.scene, this.scene.camera);
      }
    }
    // this.renderer.info.reset();
  }

  public dispose(): void {
    window.removeEventListener("resize", this.onResize);
    ticker.removeCallback(this.animate);
  }
}

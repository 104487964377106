import { PerspectiveCamera, Vector3 } from "three";

export const polarSphericalToCathesian = (
  coords: Vector3,
  target: Vector3 = new Vector3()
) => {
  const { x: r, y: theta, z: phi } = coords;
  const x = r * Math.cos(phi) * Math.sin(theta);
  const y = r * Math.sin(theta) * Math.sin(phi);
  const z = r * Math.cos(theta);

  target.set(x, y, z);
  return target;
};

export const carthesianToPolarSpherical = (
  coords: Vector3,
  target: Vector3 = new Vector3()
) => {
  const r = Math.sqrt(
    coords.x * coords.x + coords.y * coords.y + coords.z * coords.z
  );
  const phi = Math.atan2(coords.y, coords.x);
  const theta = Math.atan2(
    Math.sqrt(coords.x * coords.x + coords.y * coords.y),
    coords.z
  );
  target.set(r, theta, phi);
  return target;
};

export const pointOnFibonacciSphere = (
  point: number,
  numPoints: number,
  targetVector = new Vector3()
) => {
  const rnd = 1;
  const offset = 2 / numPoints;
  const increment = Math.PI * (3 - Math.sqrt(5));

  const y = point * offset - 1 + offset / 2;
  const r = Math.sqrt(1 - Math.pow(y, 2));

  const phi = ((point + rnd) % numPoints) * increment;

  const x = Math.cos(phi) * r;
  const z = Math.sin(phi) * r;

  return targetVector.set(x, y, z);
};

export const distanceToFitInFrustum: (
  camera: PerspectiveCamera,
  size: Vector3
) => number = (camera, size) => {
  const fov = camera.fov * (Math.PI / 180);
  const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
  let dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
  let dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
  return Math.max(dx, dy);
};

import ResourceMesh from "../experience/ResourceMesh";

export enum ResourceType {
  "Tree",
  "Human",
  "Glacier",
  "Bird",
  "River",
  "Fungi",
  "Mountain",
}

export type TResource = {
  _id: string;
  type: ResourceType;
  quantity: number;
};

export type TInventoryItem = TResource & {
  connectionsCount: number;
};

export type TFocusedResource = TInventoryItem & {
  resourceMesh?: ResourceMesh; // will only exist on the Main screen.
};

export enum Mode {
  "Main" = "main",
  "Controller" = "controller",
}

export enum FlowState {
  "Idle",
  "ChooseCharacter",
  "Build",
  "Review",
  "Finish",
}

export enum InventoryBuildState {
  "Onboarding",
  "NotEnough",
  "PickedEnough",
  "PickingExtra",
  "PickedMaximum",
}

export enum ReviewState {
  "Unbalanced",
  "Balanced",
  "RefusedRebalance",
  "AcceptedRebalance",
  "CompletedRebalance",
}

export type TChainBalanceSummaryItem = {
  type: string;
  target: number;
  min: number;
  max: number;
  current: number;
  isBalanced: boolean;
  requiresMore: boolean;
  requiresFewer: boolean;
};

export enum ActionType {
  "OnWebGlInit",
  "OnMainSceneInit",
  "SetSocket",
  "JoinedRoom",
  "OnControllerConnectionChange",
  "OnSyncState",
  "Start",
  "PickCharacterType",
  "ShowCharacterImage",
  "Reset",
  "SelectResource",
  "DeselectResource",
  "SetInventoryBuildState",
  "SetFocusedResource",
  "OnPickingExtra",
  "OnFinishedPicking",
  "OnReviewed",
  "OnRebalanced",
  "ShowResetWarning",
  "ShowTabletHint",
  "SetConnectionIsOffline",
}

export interface Action {
  type: ActionType;
  value?: any;
}

export enum Sizes {
  "S" = 1,
  "M" = 5,
  "L" = 10,
}

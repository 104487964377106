.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #000;
  color: #fff;
  background-image: url("../images/take-home-bg.png");
  background-size: 45px 45px;
  background-position: center;
  padding-bottom: 5rem;
}

.section {
  padding: 0 1rem;
  max-width: 130rem;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin: 1em 0;
  max-width: 30ch;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: clamp(2rem, 7.7vw, 5rem);
  text-align: center;
}

.chain {
  border: 1px solid #fff;
  display: inline-block;
  border-radius: 111px;
  padding: 0 0.35em;
}

.intro {
  font-size: clamp(1.5rem, 5vw, 3rem);
  max-width: 40ch;
  text-align: center;
  margin: 2em auto;
}

.article-title {
  font-size: min(10vw, 8rem);
  text-align: center;
  line-height: 0.9em;
  max-width: 14ch;
  margin: 1.5em auto;
}

.caps {
  text-transform: uppercase;
}

.article {
  font-size: clamp(1.5rem, 5vw, 3rem);
  max-width: 40ch;
  margin-left: auto;
  margin-right: auto;
}

.listen {
  margin: min(15vw, 7rem) auto;
}

.silent {
  font-size: clamp(1rem, 2vw, 2rem);
  margin: auto;
  text-align: center;
  margin-bottom: 1.5em;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  font-family: "Blur";
  background: #fff;
  border: none;
  outline: none;
  font-size: clamp(1.5rem, 3vw, 2rem);
  padding: 0 0.8em 0 0.8em;
  border-radius: 111px;
  transition: all 0.1s ease;
  box-shadow: 0px 0px 20px 5px rgba(#fff, 1);
  color: #000;
  height: 1.66em;
  margin: auto;
}

.button:disabled {
  opacity: 0.4;
  pointer-events: none;
  color: #fff;
  background-color: rgba(#fff, 0.4);
  box-shadow: 0px 0px 20px 5px rgba(#fff, 0.3);
}

.button > svg {
  height: 70%;
  width: auto;
  margin-left: 0.3em;
}

.elements-wrapper {
  margin: 4rem 0;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
}

.elements {
  width: 100%;
  height: auto;

  filter: drop-shadow(0px 0px 8px #fff);
}

.count {
  position: absolute;
  left: 0;
  color: #000;
  background: #fff;
  border-radius: 111px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, 2vw, 1.5rem);
  transform: translate(-50%, -50%);
  width: 2.5ch;
  height: 2.5ch;
}

.count:empty {
  display: none;
}

.count--human {
  top: 20%;
  left: 8%;
}

.count--mountain {
  top: 20%;
  left: 46%;
}

.count--river {
  top: 5%;
  left: 73%;
}

.count--glacier {
  top: 57%;
  left: 33%;
}

.count--fungi {
  top: 55%;
  left: 44%;
}

.count--tree {
  top: 69%;
  left: 65%;
}

.count--bird {
  top: 56%;
  left: 90%;
}

import * as classes from "./take-home.module.scss";
import { FC, useMemo, useState } from "react";
import TakeHomeCanvas from "../components/TakeHomeCanvas/TakeHomeCanvas";
import { ResourceType, TResource } from "../contexts/types";
import Elements from "../components/svg/Elements.svg";

const TakeHome: FC<{
  chain: TResource[];
  name: string;
  playChain: () => void;
}> = ({ chain, name, playChain }) => {
  const [hasClickedPlay, setHasClickedPlay] = useState(false);
  const quantities =
    chain && name
      ? useMemo(() => {
          const result = {};
          chain.forEach(({ type, quantity }) => {
            if (result[type]) {
              result[type] += quantity;
            } else {
              result[type] = quantity;
            }
          });
          return result;
        }, [])
      : null;
  return (
    <main className={classes.root}>
      {name && chain ? (
        <>
          <div className={classes.section}>
            <h1 className={classes.title}>
              This is your{" "}
              <span className={classes.chain}>Solidarity Chain</span> playing as
              [{name}]
            </h1>
          </div>
          <TakeHomeCanvas chain={chain} />
          <section className={classes.section}>
            <div className={classes.listen}>
              <p className={classes.silent}>
                Make sure to switch off silent mode
              </p>
              <button
                onClick={() => {
                  playChain();
                  setHasClickedPlay(true);
                }}
                className={classes.button}
                disabled={hasClickedPlay}
              >
                Listen
                <svg
                  viewBox="0 0 17 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.66172 3.65458C5.26473 3.79729 5 4.17377 5 4.59562V5.03621V9.80366V15.9156C4.54537 15.6996 4.0368 15.5787 3.5 15.5787C1.567 15.5787 0 17.1457 0 19.0787C0 21.0117 1.567 22.5787 3.5 22.5787C5.31947 22.5787 6.81467 21.1904 6.98403 19.4153H7V19.0787V10.5068L15 7.63101V12.9156C14.5454 12.6996 14.0368 12.5787 13.5 12.5787C11.567 12.5787 10 14.1457 10 16.0787C10 18.0117 11.567 19.5787 13.5 19.5787C15.2768 19.5787 16.7444 18.2547 16.9699 16.5394H17V16.0787V6.20889V2.16035V1.00085C17 0.308447 16.3133 -0.174421 15.6617 0.0598094L5.66172 3.65458Z"
                    fill="#202020"
                  />
                </svg>
              </button>
            </div>
            <p className={classes.intro}>
              This chain is perfectly balanced and has been added to the World
              of Shared Prosperity. It is composed of:
            </p>
            <div className={classes["elements-wrapper"]}>
              <Elements className={classes.elements} />
              <span className={`${classes.count} ${classes["count--human"]}`}>
                {quantities[ResourceType.Human]}
              </span>
              <span
                className={`${classes.count} ${classes["count--mountain"]}`}
              >
                {quantities[ResourceType.Mountain]}
              </span>
              <span className={`${classes.count} ${classes["count--river"]}`}>
                {quantities[ResourceType.River]}
              </span>
              <span className={`${classes.count} ${classes["count--bird"]}`}>
                {quantities[ResourceType.Bird]}
              </span>
              <span className={`${classes.count} ${classes["count--glacier"]}`}>
                {quantities[ResourceType.Glacier]}
              </span>
              <span className={`${classes.count} ${classes["count--tree"]}`}>
                {quantities[ResourceType.Tree]}
              </span>
              <span className={`${classes.count} ${classes["count--fungi"]}`}>
                {quantities[ResourceType.Fungi]}
              </span>
            </div>
          </section>
        </>
      ) : null}
      <section className={classes.section}>
        <h2 className={classes["article-title"]}>
          The <span className={classes.caps}>World</span> of{" "}
          <span className={classes.caps}>Shared Prosperity</span>
        </h2>

        <p className={classes.article}>
          Welcome to the future! You have found a way to share with others, to
          link your vitality with the ceaseless life of this immense planet and
          all the systems and beings that sustain it. In this world, we are
          creating together, there is no room for human exceptionalism, but why
          should we want that? What purpose is there in standing apart and
          gathering into our own arms everything we can feed into the maw of
          human progress only to find that our knowledge is not a form of
          enhanced wisdom after all?
        </p>
        <p className={classes.article}>
          Protecting the planet means rebuilding equity and trust not just with
          other humans, but with all other beings, entities, systems and life
          forms. The basis of shared prosperity goes far beyond our current
          understandings of diversity and difference to create a liveable
          economy in the service of life, productive of long term well-being.
          This will require a profound transformation in the way we think about
          value and how we manage uncertainty and interdependence. Solidarity is
          the new currency; it builds empowerment, resilience, care and
          protection. Tackling climate change, biodiversity loss, toxic
          environments, conflict and inequality calls for a systemic change, a
          transformation in what it means to act humanely on this planet.
          Mobilising international action now needs to encompass more than
          merely human diversity, and requires new institutions and forms of
          care recognising that human and planetary health and well-being are
          deeply entwined. The future depends on collaboration. You have already
          taken the first step. Welcome!
        </p>
        <p className={classes.article}>
          Learn more{" "}
          <a href="https://seriouslydifferent.org">seriouslydifferent.org</a>
        </p>

        <p className={classes.article}>
          Artwork by Kim Boutin & David Broner
          <br />
          Digital Direction, <a href="https://www.dvtk.us">DVTK</a> (Kim Boutin,
          David Broner, Manon Carrour, Julie Daled, Pauline Sonmereyn)
          <br />
          UX, <a href="https://instagram.com/vincentalpha">Vincent Charamon</a>
          <br />
          3D, <a href="https://530degres.agency/">530 Degres</a>
          <br />
          Code, <a href="https://www.isjackwild.com">Jack Wild</a>
          <br />
          Graphics, <a href="https://virgileflores.com/">Virgile Flores</a>
          <br />
          Art Direction Assistant,{" "}
          <a href="https://instagram.com/_erwinsan">Erwin San</a>
          <br />
          Sound, <a href="https://8salamanda8.bandcamp.com/">Salamanda</a>
        </p>
      </section>
    </main>
  );
};
export default TakeHome;

import {
  AdditiveBlending,
  BufferGeometry,
  DoubleSide,
  Float32BufferAttribute,
  MathUtils,
  Object3D,
  Points,
  PointsMaterial,
  Sprite,
  SpriteMaterial,
  sRGBEncoding,
  Texture,
  TextureLoader,
  Vector3,
} from "three";
import SimplexNoise from "simplex-noise";
import * as spriteSrcs from "url:../maps/floaty-particles/*.png";
import { polarSphericalToCathesian } from "../util/maths";

const textureLoader = new TextureLoader();

const noise = new SimplexNoise();

const TMP_V3 = new Vector3();

class Particle extends Sprite {
  public naturalPosition = new Vector3();
  private offsetPosition = new Vector3();
  private floatValue = Math.random() * Math.PI * 20;
  private floatSpeed = MathUtils.randFloat(0.5, 1);

  constructor(args) {
    super(args);
  }

  public update(correction) {
    this.floatValue += 0.005 * correction * this.floatSpeed;
    this.offsetPosition.y = Math.cos(this.floatValue) * this.scale.y;
    this.offsetPosition.x =
      Math.sin((this.floatValue + 10) * 0.1) * this.scale.x * 2;

    this.position.addVectors(this.naturalPosition, this.offsetPosition);
  }
}

class FloatyParticlesSprites extends Object3D {
  private textures?: Texture[];
  private radius: number;
  private sprites: Particle[] = [];
  private spriteScale = 10;
  private count = 0;
  private mode: "sphere" | "flat";

  constructor(
    radius = 10,
    scale = 8,
    count = 70,
    mode: "sphere" | "flat" = "flat"
  ) {
    super();
    this.radius = radius;
    this.spriteScale = scale;
    this.mode = mode;
    this.count = count;
    this.init();
  }

  private async init() {
    this.textures = await Promise.all(
      Object.keys(spriteSrcs).map(async (key) => {
        const texture = await textureLoader.loadAsync(spriteSrcs[key]);
        texture.encoding = sRGBEncoding;
        return texture;
      })
    );

    for (let i = 0; i < this.count; i++) {
      this.spawnParticle(this.textures[i % this.textures.length]);
    }
    this.repositionParticles();
  }

  private spawnParticle(map: Texture) {
    const sprite = new Particle(
      new SpriteMaterial({
        map,
        color: 0xffffff,
        fog: false,
        depthTest: false,
        transparent: false,
        blending: AdditiveBlending,
        side: DoubleSide,
      })
    );
    sprite.renderOrder = Math.random() > 0.25 ? -1 : 1;
    this.sprites.push(sprite);
    sprite.scale.set(map.image.width, map.image.height, 1);
    sprite.scale.multiplyScalar(this.spriteScale * 0.003);
    this.add(sprite);
  }

  public repositionParticles() {
    this.sprites.forEach((sprite) => {
      if (this.mode === "flat") {
        sprite.naturalPosition.set(
          MathUtils.randFloatSpread(this.radius),
          MathUtils.randFloatSpread(this.radius),
          0
        );
      } else {
        const theta = Math.random() * Math.PI * 2;
        const phi = Math.random() * Math.PI;
        TMP_V3.set(this.radius, theta, phi);

        polarSphericalToCathesian(TMP_V3, sprite.naturalPosition);
      }
    });
  }

  public update(correction = 1) {
    this.sprites.forEach((sprite) => {
      sprite.update(correction);
    });
  }
}

export default FloatyParticlesSprites;

@import "../../scss/variables.scss";

.root {
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  appearance: none;
  background: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: "Blur";
  color: #fff;
  cursor: pointer;
}

.label:not(:last-child) {
  margin-bottom: 3rem;
}

.label {
  @include controller-button;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000;
  transition: filter 0.1s ease;
}

.root:active .label {
  filter: invert(1);
}

.label--none {
  color: #fff;
  text-shadow: $elevation--drop-shadow-controller;
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.label--neutral {
  color: #000;
  background: #fff;
  box-shadow: 0px 0px 30px #fff;
}

.label--positive {
  background: $colour--yellow;
  box-shadow: 0px 0px 30px $colour--yellow;
}

.label--negative {
  background: $colour--orange;
  box-shadow: 0px 0px 30px $colour--orange;
}

.message {
  @include toVw(font-size, 20, 0.8rem, 10rem, 1180);
  letter-spacing: 0.3em;
  text-shadow: $elevation--drop-shadow-controller;
  max-width: 45ch;
}

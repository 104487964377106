import { useCallback, useEffect, useRef, useState } from "react";
import { Vector2 } from "three";
import { clamp } from "lodash";
import * as classes from "./joystick.module.scss";
import classNames from "classnames";
import Move from "./Move.svg";

type Vec2 = {
  x: number;
  y: number;
};

const TMP_V2 = new Vector2();
(window as any).joystick = (window as any).joystick || {};

const Joystick = ({
  name,
  onClick,
  onTouched,
  onMoved,
  colour = "white",
}: {
  name: string;
  onClick?: () => void;
  onTouched?: () => void;
  onMoved?: () => void;
  colour: "white" | "blue" | "yellow";
}) => {
  const rafRef = useRef<any>();
  const rootEl = useRef<HTMLDivElement | null>(null);
  const [radius, setRadius] = useState(0);
  const [touchStartPosition, setTouchStartPosition] = useState<Vec2>({
    x: 0,
    y: 0,
  });
  const [isTouched, setIsTouched] = useState(false);
  const [offset, setOffset] = useState<Vec2>({ x: 0, y: 0 });
  const [touchIndex, setTouchIndex] = useState<number | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const disableButtonTimeout = useRef();

  useEffect(() => {
    (window as any).joystick[name] = { x: 0, y: 0 };

    const onResize = () => {
      setRadius(rootEl.current.clientWidth * 0.5);
    };
    window.addEventListener("resize", onResize);
    setRadius(rootEl.current.clientWidth * 0.5);

    return () => {
      clearTimeout(disableButtonTimeout.current);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    const value = {
      x: clamp(offset.x / radius, -1, 1),
      y: clamp(offset.y / radius, -1, 1),
    };
    (window as any).joystick[name] = value;
  }, [offset, radius]);

  const onTouchStart = useCallback(
    (e) => {
      onTouched();
      const touchIndex = e.touches ? e.touches.length - 1 : null;
      setTouchIndex(e.touches ? e.touches.length - 1 : null);

      const { clientX: x, clientY: y } = e.touches ? e.touches[touchIndex] : e;
      setTouchStartPosition({ x, y });
      setIsTouched(true);
    },
    [radius]
  );

  const onTouchEnd = useCallback(
    (e) => {
      setIsTouched(false);
      clearTimeout(disableButtonTimeout.current);
      disableButtonTimeout.current = setTimeout(() => {
        setDisableButton(false);
      }, 222);
      // setOffset({ x: 0, y: 0 });
    },
    [radius]
  );

  const onTouchMove = useCallback(
    (e) => {
      const { clientX: x, clientY: y } = e.touches ? e.touches[touchIndex] : e;

      TMP_V2.x = x - touchStartPosition.x;
      TMP_V2.y = y - touchStartPosition.y;
      TMP_V2.clampLength(0, radius * 2);

      clearTimeout(disableButtonTimeout.current);
      setDisableButton(true);

      if (TMP_V2.length() > 15) {
        onMoved();
      }

      const offset = {
        x: TMP_V2.x,
        y: TMP_V2.y,
      };

      setOffset(offset);
    },
    [radius, touchStartPosition, touchIndex]
  );

  useEffect(() => {
    if (isTouched) {
      cancelAnimationFrame(rafRef.current);
      window.addEventListener("mousemove", onTouchMove);
      window.addEventListener("touchmove", onTouchMove);
      window.addEventListener("touchend", onTouchEnd);
      window.addEventListener("mouseup", onTouchEnd);
    } else {
      const targetOffset = { x: 0, y: 0 };
      const currentOffset = { x: offset.x, y: offset.y };
      let then = Date.now();

      const animateToCenter = () => {
        const now = Date.now();
        const delta = Math.min((now - then) / 16.666, 4);
        currentOffset.x += (targetOffset.x - currentOffset.x) * 0.1 * delta;
        currentOffset.y += (targetOffset.y - currentOffset.y) * 0.1 * delta;

        TMP_V2.x = currentOffset.x;
        TMP_V2.y = currentOffset.y;
        TMP_V2.clampLength(0, radius);

        currentOffset.x = TMP_V2.x;
        currentOffset.y = TMP_V2.y;

        if (Math.abs(currentOffset.x) < 1 && Math.abs(currentOffset.y) < 1) {
          currentOffset.x = 0;
          currentOffset.y = 0;
        } else {
          then = now;
          rafRef.current = requestAnimationFrame(animateToCenter);
        }

        setOffset({ ...currentOffset });
      };

      rafRef.current = requestAnimationFrame(animateToCenter);
    }

    return () => {
      cancelAnimationFrame(rafRef.current);
      window.removeEventListener("mousemove", onTouchMove);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchend", onTouchEnd);
      window.removeEventListener("mouseup", onTouchEnd);
    };
  }, [isTouched]);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === " ") {
        onClick();
      }
    };
    if (onClick) window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onClick]);

  const onClickButton = () => {
    setDisableButton(true);
    onClick();

    disableButtonTimeout.current = setTimeout(() => {
      setDisableButton(false);
    }, 999);
  };

  return (
    <div
      className={classes.root}
      ref={rootEl}
      style={{
        "--color":
          colour === "yellow"
            ? "#ebff00"
            : colour === "blue"
            ? "#7ef7e8"
            : "#fff",
      }}
    >
      <div
        className={classNames(
          classes["touch"],
          classes[`touch--${colour}`],
          {
            [classes["touch--is-visible"]]: !isTouched,
          },
          {
            [classes["touch--can-click"]]: !!onClick,
          }
        )}
      ></div>
      <div
        className={classes.pad}
        onTouchStart={onTouchStart}
        onMouseDown={onTouchStart}
        style={{ transform: `translate(${offset.x}px, ${offset.y}px)` }}
      >
        <div
          style={{
            top: `${touchStartPosition.y}px`,
            left: `${touchStartPosition.x}px`,
          }}
          className={classNames(
            classes["move"],
            {
              [classes["move--is-visible"]]: isTouched,
            },
            {
              [classes["move--can-click"]]: !!onClick,
            }
          )}
        >
          <Move />
        </div>
        <button
          onClick={disableButton ? null : onClickButton}
          disabled={disableButton || !onClick}
        ></button>
      </div>
    </div>
  );
};

export default Joystick;

{
  "resources": [
    {
      "_id": "resource_0-0-0",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-1",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-2",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-3",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-4",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-5",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-6",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-7",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-8",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-9",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-10",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-11",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-12",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-13",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-14",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-15",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-16",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-17",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-18",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-0-19",
      "quantity": 1,
      "type": 0
    },
    {
      "_id": "resource_0-1-0",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-1",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-2",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-3",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-4",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-5",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-6",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-7",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-8",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-9",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-10",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-11",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-12",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-13",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-1-14",
      "quantity": 5,
      "type": 0
    },
    {
      "_id": "resource_0-2-0",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_0-2-1",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_0-2-2",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_0-2-3",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_0-2-4",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_0-2-5",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_0-2-6",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_0-2-7",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_0-2-8",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_0-2-9",
      "quantity": 10,
      "type": 0
    },
    {
      "_id": "resource_1-0-0",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-1",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-2",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-3",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-4",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-5",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-6",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-7",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-8",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-9",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-10",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-11",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-12",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-13",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-14",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-15",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-16",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-17",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-18",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-0-19",
      "quantity": 1,
      "type": 1
    },
    {
      "_id": "resource_1-1-0",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-1",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-2",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-3",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-4",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-5",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-6",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-7",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-8",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-9",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-10",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-11",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-12",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-13",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-1-14",
      "quantity": 5,
      "type": 1
    },
    {
      "_id": "resource_1-2-0",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_1-2-1",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_1-2-2",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_1-2-3",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_1-2-4",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_1-2-5",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_1-2-6",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_1-2-7",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_1-2-8",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_1-2-9",
      "quantity": 10,
      "type": 1
    },
    {
      "_id": "resource_2-0-0",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-1",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-2",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-3",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-4",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-5",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-6",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-7",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-8",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-9",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-10",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-11",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-12",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-13",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-14",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-15",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-16",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-17",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-18",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-0-19",
      "quantity": 1,
      "type": 2
    },
    {
      "_id": "resource_2-1-0",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-1",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-2",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-3",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-4",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-5",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-6",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-7",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-8",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-9",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-10",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-11",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-12",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-13",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-1-14",
      "quantity": 5,
      "type": 2
    },
    {
      "_id": "resource_2-2-0",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_2-2-1",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_2-2-2",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_2-2-3",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_2-2-4",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_2-2-5",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_2-2-6",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_2-2-7",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_2-2-8",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_2-2-9",
      "quantity": 10,
      "type": 2
    },
    {
      "_id": "resource_3-0-0",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-1",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-2",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-3",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-4",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-5",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-6",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-7",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-8",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-9",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-10",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-11",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-12",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-13",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-14",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-15",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-16",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-17",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-18",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-0-19",
      "quantity": 1,
      "type": 3
    },
    {
      "_id": "resource_3-1-0",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-1",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-2",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-3",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-4",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-5",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-6",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-7",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-8",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-9",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-10",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-11",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-12",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-13",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-1-14",
      "quantity": 5,
      "type": 3
    },
    {
      "_id": "resource_3-2-0",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_3-2-1",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_3-2-2",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_3-2-3",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_3-2-4",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_3-2-5",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_3-2-6",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_3-2-7",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_3-2-8",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_3-2-9",
      "quantity": 10,
      "type": 3
    },
    {
      "_id": "resource_4-0-0",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-1",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-2",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-3",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-4",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-5",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-6",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-7",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-8",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-9",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-10",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-11",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-12",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-13",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-14",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-15",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-16",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-17",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-18",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-0-19",
      "quantity": 1,
      "type": 4
    },
    {
      "_id": "resource_4-1-0",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-1",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-2",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-3",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-4",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-5",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-6",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-7",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-8",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-9",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-10",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-11",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-12",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-13",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-1-14",
      "quantity": 5,
      "type": 4
    },
    {
      "_id": "resource_4-2-0",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_4-2-1",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_4-2-2",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_4-2-3",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_4-2-4",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_4-2-5",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_4-2-6",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_4-2-7",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_4-2-8",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_4-2-9",
      "quantity": 10,
      "type": 4
    },
    {
      "_id": "resource_5-0-0",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-1",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-2",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-3",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-4",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-5",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-6",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-7",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-8",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-9",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-10",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-11",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-12",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-13",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-14",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-15",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-16",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-17",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-18",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-0-19",
      "quantity": 1,
      "type": 5
    },
    {
      "_id": "resource_5-1-0",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-1",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-2",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-3",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-4",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-5",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-6",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-7",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-8",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-9",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-10",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-11",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-12",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-13",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-1-14",
      "quantity": 5,
      "type": 5
    },
    {
      "_id": "resource_5-2-0",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_5-2-1",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_5-2-2",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_5-2-3",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_5-2-4",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_5-2-5",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_5-2-6",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_5-2-7",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_5-2-8",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_5-2-9",
      "quantity": 10,
      "type": 5
    },
    {
      "_id": "resource_6-0-0",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-1",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-2",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-3",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-4",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-5",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-6",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-7",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-8",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-9",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-10",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-11",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-12",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-13",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-14",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-15",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-16",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-17",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-18",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-0-19",
      "quantity": 1,
      "type": 6
    },
    {
      "_id": "resource_6-1-0",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-1",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-2",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-3",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-4",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-5",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-6",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-7",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-8",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-9",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-10",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-11",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-12",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-13",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-1-14",
      "quantity": 5,
      "type": 6
    },
    {
      "_id": "resource_6-2-0",
      "quantity": 10,
      "type": 6
    },
    {
      "_id": "resource_6-2-1",
      "quantity": 10,
      "type": 6
    },
    {
      "_id": "resource_6-2-2",
      "quantity": 10,
      "type": 6
    },
    {
      "_id": "resource_6-2-3",
      "quantity": 10,
      "type": 6
    },
    {
      "_id": "resource_6-2-4",
      "quantity": 10,
      "type": 6
    },
    {
      "_id": "resource_6-2-5",
      "quantity": 10,
      "type": 6
    },
    {
      "_id": "resource_6-2-6",
      "quantity": 10,
      "type": 6
    },
    {
      "_id": "resource_6-2-7",
      "quantity": 10,
      "type": 6
    },
    {
      "_id": "resource_6-2-8",
      "quantity": 10,
      "type": 6
    },
    {
      "_id": "resource_6-2-9",
      "quantity": 10,
      "type": 6
    }
  ]
}

import {
  Mesh,
  Scene,
  sRGBEncoding,
  Vector2,
  Vector3,
  WebGLRenderer,
  TextureLoader,
  EquirectangularReflectionMapping,
  MeshMatcapMaterial,
  Material,
  Euler,
  Fog,
  FrontSide,
  MeshNormalMaterial,
} from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import {
  FlowState,
  ResourceType,
  TInventoryItem,
  TResource,
} from "../contexts/types";
import FocusController from "./FocusController";
import hdrSrc from "url:../maps/studio033.hdr";
import backgroundSrc from "url:../maps/Grid-Transparent.png";

import { shuffle } from "lodash";
import matcapSrcs from "url:../maps/matcaps/*";
import ResourceWorld from "./ResourceWorld";
import { modelMapSrcs } from "../config/config";
import CharacterWorld from "./CharacterWorld";
import AnimatedCamera from "./AnimatedCamera";
import { distanceToFitInFrustum } from "../util/maths";
import LightingRig from "./LightingRig";
import { addChunks } from "../util/addChunks";

const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("libs/draco/");
dracoLoader.setDecoderConfig({ type: "js" });
gltfLoader.setDRACOLoader(dracoLoader);
const rgbeLoader = new RGBELoader();
const textureLoader = new TextureLoader();

let MATCAPS_TEST;
const USE_MATCAP = false;

const OVERRIDE_NORMAL_MATERIAL = false;

export const SPHERE_RADIUS_MIN = 10,
  SPHERE_RADIUS_MAX = 12;
export const SKYBOX_RADIUS = 25;

export const CAMERA_IDLE_VIEW_DIST = 270;

const CAMERA_IDLE_POSITION = new Vector3(0, 0.3, 1)
  .normalize()
  .multiplyScalar(CAMERA_IDLE_VIEW_DIST);
const CAMERA_BUILD_POSITION = new Vector3(0, 0, 0.1);
// const CAMERA_REVIEW_POSITION = new Vector3(0, 0, 1).multiplyScalar(
//   SPHERE_RADIUS_MAX + 1
// );

const MINIMAP_BUILD_POSITION = new Vector3(0, 0, 1).multiplyScalar(18);
const MINIMAP_REVIEW_POSITION = new Vector3(0, 0, 1);

const TMP_V3 = new Vector3();
const TMP_EULER = new Euler();

class MainScene extends Scene {
  private models: Map<string, GLTF> = new Map();
  public camera = new AnimatedCamera(25, 1, 0.1, CAMERA_IDLE_VIEW_DIST * 2);
  public minimapCamera = new AnimatedCamera(
    95,
    1,
    0.1,
    CAMERA_IDLE_VIEW_DIST * 2
  );
  private focusController = new FocusController(this.camera);
  private resourceWorld = new ResourceWorld(this.focusController);
  private characterWorld: CharacterWorld;
  private _joystickValue = new Vector2();
  private _flowState: FlowState;
  private _inventory: TInventoryItem[] = [];
  private renderer: WebGLRenderer;
  private _cameraInsideSphere = null;

  constructor(renderer: WebGLRenderer) {
    super();

    this.renderer = renderer;

    this.characterWorld = new CharacterWorld(this.renderer);

    this.camera.viewPosition = CAMERA_IDLE_POSITION;
    this.add(this.camera);
    this.layers.enable(1);

    this.minimapCamera.viewPosition = MINIMAP_BUILD_POSITION;
    this.minimapCamera.position.copy(MINIMAP_BUILD_POSITION);
    this.minimapCamera.layers.disable(0);
    this.minimapCamera.layers.enable(1);
    this.add(this.minimapCamera);

    this.add(this.resourceWorld);
    this.add(this.characterWorld);
    this.fog = new Fog(
      0x000000,
      CAMERA_IDLE_VIEW_DIST * 0.66,
      CAMERA_IDLE_VIEW_DIST * 1.66
    );
    this.add(new LightingRig());
    this.cameraInsideSphere = false;
  }

  private async loadModels(): Promise<void> {
    if (USE_MATCAP) {
      MATCAPS_TEST = await Promise.all(
        Object.keys(matcapSrcs).map((key) =>
          textureLoader.loadAsync(matcapSrcs[key])
        )
      );
    }

    MATCAPS_TEST = shuffle(MATCAPS_TEST);

    let i = 0;
    await Promise.all(
      [...modelMapSrcs.entries()].map(async ([key, src]) => {
        const model = await gltfLoader.loadAsync(src);
        if (USE_MATCAP) {
          model.scene.traverse((child) => {
            if (child instanceof Mesh) {
              ((child as Mesh).material as Material).dispose();
              (child as Mesh).material = new MeshMatcapMaterial({
                matcap: MATCAPS_TEST[i % MATCAPS_TEST.length],
              });
              i++;
            }
          });
        } else {
          model.scene.traverse((child) => {
            if (child instanceof Mesh) {
              if (OVERRIDE_NORMAL_MATERIAL) {
                ((child as Mesh).material as Material).dispose();
                (child as Mesh).material = new MeshNormalMaterial();
              } else {
                child.material.onBeforeCompile = (shader) => {
                  shader.vertexShader = addChunks(shader.vertexShader, [
                    [
                      "start",
                      `
                      out vec3 vWorldNormal;
                      out float vFresnel;
                    `,
                    ],
                    [
                      "end",
                      `
                      vWorldNormal = normalize(mat3(modelMatrix) * normal);
                      vFresnel = max(0., 1. - dot(-normalize(worldPosition.xyz - cameraPosition), normalize(vWorldNormal)));
                    `,
                    ],
                  ]);
                  shader.fragmentShader = addChunks(shader.fragmentShader, [
                    [
                      "start",
                      `
                      in vec3 vWorldNormal;
                      in float vFresnel;

                      float LightingFuncGGX_D(float dotNH, float roughness)
                      {
                        float alpha = roughness*roughness;
                        float alphaSqr = alpha*alpha;
                        float pi = 3.14159f;
                        float denom = dotNH * dotNH *(alphaSqr-1.0) + 1.0f;

                        float D = alphaSqr/(pi * denom * denom);
                        return D;
                      }
                    `,
                    ],
                    [
                      "end",
                      `
                      vec3 H = normalize(normalize(vViewPosition) + normalize(vec3(-1., 0., -.5)));
                      gl_FragColor += LightingFuncGGX_D(dot(normal, H), .2);
                      gl_FragColor += smoothstep(.5, 1., vFresnel) * .1;
                    `,
                    ],
                  ]);
                };
              }
              child.material.side = FrontSide;
              child.geometry.rotateX(Math.PI * 0.5);
              i++;
            }
          });
        }
        this.models.set(key, model);
      })
    );
  }

  public async init(): Promise<void> {
    const hdr = await rgbeLoader.loadAsync(hdrSrc);
    hdr.mapping = EquirectangularReflectionMapping;
    this.environment = hdr;
    this.background = await textureLoader.loadAsync(backgroundSrc);
    // this.background.encoding = sRGBEncoding;

    await this.loadModels();

    this.models.forEach((model, name) => {
      model.scene.traverse((child) => {
        if (child instanceof Mesh) {
          if (child.material.opacity < 1 || child.material.transmission > 0) {
            child.material.transparent = true;
          } else {
            child.material.transparent = false;
          }
          child.material.side = FrontSide;
          if (child.material.map) {
            child.material.map.encoding = sRGBEncoding;
          }
        }
      });
    });

    this.resourceWorld.addResourceMeshes(this.models);

    this.traverse((child) => {
      if (child.layers) {
        child.layers.disable(9);
      }
    });

    this.characterWorld.addCharacterMeshes(this.models);
  }

  public set isEnventoryPickingEnabled(isPicking) {
    this.focusController.isEnabled = isPicking;
  }

  public set isOnboardingFinished(isFinished) {
    this.resourceWorld.visuallyHidden = !isFinished;
  }

  public set joystickValue({ x, y }: Vector2) {
    this._joystickValue.set(x, y);
  }

  public get joystickValue() {
    return this._joystickValue;
  }

  public set cachedResourceConnections(
    cachedResourceConnections: Map<string, string[]>
  ) {
    this.resourceWorld.cachedResourceConnections = cachedResourceConnections;
  }

  public set inventory(inventory: TInventoryItem[]) {
    this._inventory = inventory;
    this.resourceWorld.inventory = inventory;

    requestIdleCallback(
      () => {
        if (this.flowState === FlowState.Finish) {
          this.arrangeChain();
        }
        this.resourceWorld.sky.material.changeGradient(this.flowState);
      },
      { timeout: 333 }
    );
  }

  public get inventory() {
    return this._inventory;
  }

  public get outlinedObjectsYellow(): Mesh[] {
    if (this.focusController.focusedResource) {
      return this.focusController.focusedResource.modelGroup.children;
    }
    return [];
  }

  public get outlinedObjectsBlue(): Mesh[] {
    const objects = [];
    if (this.resourceWorld.character) {
      objects.push(...this.resourceWorld.character.modelGroup.children);
    }
    this.inventory.forEach(({ _id }) => {
      objects.push(...this.getObjectByName(_id)?.modelGroup.children);
    });
    return objects;
  }

  // sets the resource data, and then we go and find the actual character mesh
  public set characterResource(resource: TResource | null) {
    this.resourceWorld.characterResource = resource;
  }

  public set characterType(type: ResourceType) {
    this.characterWorld.characterType = type;
  }

  public set flowState(flowState: FlowState) {
    this._flowState = flowState;

    if (
      flowState === FlowState.Idle ||
      flowState == FlowState.ChooseCharacter
    ) {
      this.resourceWorld.unsummonChain();
      this.characterWorld.visible = true;
      this.characterWorld.matrixAutoUpdate = true;
      this.camera.viewPosition = CAMERA_IDLE_POSITION;
      this.minimapCamera.viewPosition = MINIMAP_BUILD_POSITION;
    } else if (flowState === FlowState.Build) {
      this.camera.viewPosition = CAMERA_BUILD_POSITION;
      this.minimapCamera.viewPosition = MINIMAP_BUILD_POSITION;
    } else if (
      flowState === FlowState.Review ||
      flowState === FlowState.Finish
    ) {
      this.arrangeChain();
    }

    this.resourceWorld.sky.material.changeGradient(this.flowState);

    if (
      flowState === FlowState.Idle ||
      flowState === FlowState.ChooseCharacter ||
      flowState === FlowState.Review
    ) {
      this.resourceWorld.targetRotation = TMP_EULER.set(
        0,
        this.resourceWorld.rotation.y,
        this.resourceWorld.rotation.z
      );
    }

    if (flowState === FlowState.Review) {
      this.resourceWorld.sky.material.fadeInBlack();
    } else if (
      flowState === FlowState.ChooseCharacter ||
      flowState === FlowState.Idle
    ) {
      this.resourceWorld.sky.material.fadeOutBlack();
    }
  }

  public get flowState() {
    return this._flowState;
  }

  private arrangeChain() {
    const radius = this.resourceWorld.summonChain(this.inventory);
    const cameraDist = Math.max(
      distanceToFitInFrustum(
        this.camera,
        TMP_V3.set(radius * 2, radius * 2, 1)
      ),
      7
    );
    this.camera.viewPosition = TMP_V3.set(0, 0, 1).multiplyScalar(
      cameraDist * 1.33
    );

    const cameraDistMinimap = distanceToFitInFrustum(
      this.minimapCamera,
      TMP_V3.set(radius * 2, radius * 2, 1)
    );
    this.minimapCamera.viewPosition = TMP_V3.copy(MINIMAP_REVIEW_POSITION)
      .normalize()
      .multiplyScalar(cameraDistMinimap * 1.33);
  }

  public drawConnectionsForInventory(
    inventory: TInventoryItem[],
    closeChain = false
  ) {
    this.resourceWorld.drawConnectionsForInventory(inventory, closeChain);
  }

  private set cameraInsideSphere(isInside) {
    if (isInside === this._cameraInsideSphere) return;
    this._cameraInsideSphere = isInside;

    this.resourceWorld.visible = isInside;
    this.characterWorld.matrixAutoUpdate = isInside;
    this.characterWorld.visible = !isInside;
    this.characterWorld.matrixAutoUpdate = !isInside;

    if (this.characterWorld.visible) {
      this.characterWorld.particles.repositionParticles();
    }

    if (this.resourceWorld.visible) {
      this.resourceWorld.particles.repositionParticles();
    }
  }

  public update(correction = 1): void {
    if (
      this.flowState === FlowState.Idle ||
      this.flowState === FlowState.ChooseCharacter
    ) {
      this.resourceWorld.incrementRotation(0, 0.001 * correction, 0);
    }

    if (this.flowState === FlowState.Build) {
      this.resourceWorld.incrementRotation(
        0,
        0.01 * correction * this.joystickValue.x,
        0.01 * correction * this.joystickValue.y
      );
    }

    this.resourceWorld.update(correction);
    this.characterWorld.update(correction);
    this.camera.update(correction);
    this.minimapCamera.update(correction);
    this.cameraInsideSphere = this.camera.position.length() <= SKYBOX_RADIUS;
  }

  public onResize(width: number, height: number) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.minimapCamera.aspect = height / height;
    this.minimapCamera.updateProjectionMatrix();
  }

  public destroy(): void {}
}

export default MainScene;

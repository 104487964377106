@import "../../scss/variables.scss";

.root {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transform: translate(-50%, -50%);
}

.wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @include toVw(width, 40);
  @include toVw(height, 40);
  background: rgba(#000, 0.5);
  border-radius: 50%;
  box-shadow: $elevation--drop-shadow;
  @include toVw(margin-right, 10);
}

.wrapper > svg {
  width: auto;
  height: auto;
  max-width: 70%;
  max-height: 70%;
  filter: $elevation--drop-shadow-filter;
}

.count {
  @include toVw(font-size, 25);
  color: #fff;
  text-shadow: $elevation--drop-shadow;
}

.root--glacier {
  left: 64%;
  top: 5%;
}

.root--tree {
  left: 70%;
  top: 16%;
}

.root--mountain {
  left: 73%;
  top: 29%;
}

.root--river {
  left: 74%;
  top: 42%;
}

.root--bird {
  left: 72%;
  top: 56%;
}

.root--human {
  left: 68%;
  top: 68%;
}

.root--fungi {
  left: 61%;
  top: 78%;
}

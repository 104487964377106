@import "../../scss/variables.scss";

.root {
  appearance: none;
  @include toVw(font-size, 35, 1rem, 999rem, 1180);
  @include controller-button;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  font-family: "Blur";
  cursor: pointer;
  position: fixed;
  @include toVw(bottom, 35, 1rem, 999rem, 1180);
  border-radius: 111px;
  color: #000;
  transition: filter 0.1s ease;
  @include generic-css-transition-in-out($translate: true);
  white-space: nowrap;
}

.root:before {
  width: 150%;
  height: 200%;
  left: -25%;
  top: -50%;
  content: "";
  display: block;
  position: absolute;
}

.root:active {
  filter: invert(1);
}

.root--grey {
  background: #202020;
  color: #fff;
}

.root--left {
  @include toVw(left, 35, 1rem, 999rem, 1180);
}

.root--right {
  @include toVw(right, 35, 1rem, 999rem, 1180);
  background-color: $colour--yellow;
  box-shadow: 0px 0px 30px $colour--yellow;
}

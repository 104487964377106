import {
  Scene,
  PerspectiveCamera,
  Mesh,
  Group,
  EquirectangularReflectionMapping,
  Vector3,
  TextureLoader,
  CanvasTexture,
} from "three";
import { getModelMapKey, modelMapSrcs } from "../config/config";
import { TResource } from "../contexts/types";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import LightingRig from "./LightingRig";
import hdrSrc from "url:../maps/studio033.hdr";
import { distanceToFitInFrustum } from "../util/maths";
import ResourceConnection from "./ResourceConnection";
import TakeHomeResourceMesh from "./TakeHomeResourceMesh";

const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("libs/draco/");
dracoLoader.setDecoderConfig({ type: "js" });
gltfLoader.setDRACOLoader(dracoLoader);
const rgbeLoader = new RGBELoader();
const textureLoader = new TextureLoader();

const TMP_V3 = new Vector3();

class TakeHomeScene extends Scene {
  private models: Map<string, GLTF> = new Map();
  public camera = new PerspectiveCamera(25);
  public chain: TResource[];
  private chainGroup = new Group();
  private connectionGroup = new Group();
  private rotationOffset = 0;
  private radius = 1;

  constructor() {
    super();
    this.add(this.camera);
    this.add(new LightingRig());
    this.add(this.chainGroup);
    this.add(this.connectionGroup);
  }

  public async init(chain: TResource[]): Promise<void> {
    const hdr = await rgbeLoader.loadAsync(hdrSrc);
    hdr.mapping = EquirectangularReflectionMapping;
    this.environment = hdr;

    this.chain = chain;

    const requiredModelKeys = [];
    chain.forEach(({ type, quantity }) => {
      const key = getModelMapKey(type, quantity);
      if (requiredModelKeys.indexOf(key) === -1) {
        requiredModelKeys.push(key);
      }
    });

    await Promise.all(
      requiredModelKeys.map(async (key) => {
        const model = await gltfLoader.loadAsync(modelMapSrcs.get(key));
        if (!model) return;
        model.scene.traverse((child) => {
          if (child instanceof Mesh) {
            child.geometry.rotateX(Math.PI * 0.5);
          }
        });
        this.models.set(key, model);
      })
    );

    const circumfrence = chain.length * 1.33;
    const radius = circumfrence / Math.PI / 2;
    this.radius = radius;
    this.chain.forEach(({ type, quantity }, i) => {
      const model = this.models.get(getModelMapKey(type, quantity));
      if (!model) return;
      const group = new TakeHomeResourceMesh(model, i);
      const theta = (i * Math.PI * 2) / chain.length;
      const x = Math.cos(theta) * radius * -1;
      const y = Math.sin(theta) * radius;
      const z = 0;
      group.position.set(x, y, z);
      this.chainGroup.add(group);
    });

    this.chainGroup.children.forEach((child, i) => {
      const from = child;
      const to =
        i === this.chainGroup.children.length - 1
          ? this.chainGroup.children[0]
          : this.chainGroup.children[i + 1];
      this.connectionGroup.add(new ResourceConnection(from, to, true, 0.3));
    });
  }

  public update(correction = 1): void {
    this.rotationOffset += 0.0005 * correction;
    this.chainGroup.children.forEach((child, i) => {
      (child as TakeHomeResourceMesh).update(correction);
      const theta = (i * Math.PI * 2) / this.chain.length + this.rotationOffset;
      const x = Math.cos(theta) * this.radius * -1;
      const y = Math.sin(theta) * this.radius;
      const z = 0;
      child.position.set(x, y, z);

      child.rotation.y +=
        0.001 *
        (i % 2 === 0 ? 1 : -1) *
        child.userData.rotationSpeed *
        correction;
    });

    this.connectionGroup.children.forEach((child) => child.update(correction));
  }

  public onResize(width: number, height: number) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    const cameraDist = distanceToFitInFrustum(
      this.camera,
      TMP_V3.set(this.radius * 2, this.radius * 2, 1)
    );
    this.camera.position.copy(
      TMP_V3.set(0, 0, 1).multiplyScalar(cameraDist * 1.33)
    );
    this.camera.lookAt(this.position);
  }

  public destroy(): void {}
}

export default TakeHomeScene;

import { Object3D, PerspectiveCamera, Raycaster, Vector2 } from "three";
import { ActionType } from "../contexts/types";
import ResourceMesh from "./ResourceMesh";

const RAYCASTER_POINTER = new Vector2();

class FocusController {
  private camera: PerspectiveCamera;
  private raycaster = new Raycaster();
  private _focusedResource: ResourceMesh | null = null;
  private _isEnabled = false;

  constructor(camera: PerspectiveCamera) {
    this.camera = camera;
  }

  public set isEnabled(isEnabled: boolean) {
    this._isEnabled = isEnabled;
    if (!isEnabled && !!this.focusedResource) {
      this.focusedResource = null;
    }
  }

  public get isEnabled() {
    return this._isEnabled;
  }

  public set focusedResource(object: ResourceMesh | null) {
    if (this.focusedResource) {
      (this.focusedResource as ResourceMesh).isFocused = false;
    }

    this._focusedResource = object;

    if (object) {
      (this.focusedResource as ResourceMesh).isFocused = true;
      (window as any).dispatch({
        type: ActionType.SetFocusedResource,
        value: {
          ...(this.focusedResource as ResourceMesh).data,
          resourceMesh: object,
          connectionsCount: (object as ResourceMesh).connectionsCount,
        },
      });
    } else {
      (window as any).dispatch({
        type: ActionType.SetFocusedResource,
        value: null,
      });
    }
  }

  public get focusedResource(): ResourceMesh | null {
    return this._focusedResource;
  }

  public checkIsFocusedElement(object: Object3D): boolean {
    return this.focusedResource === object;
  }

  public checkFocus(objects: Object3D[]) {
    if (!this.isEnabled) return;
    this.raycaster.setFromCamera(RAYCASTER_POINTER, this.camera);
    const intersects = this.raycaster.intersectObjects(objects, false);
    if (intersects.length) {
      if (intersects[0].object.parent !== this.focusedResource) {
        this.focusedResource = intersects[0].object.parent;
      }
    } else {
      if (this.focusedResource !== null) {
        this.focusedResource = null;
      }
    }
    return this.focusedResource;
  }
}

export default FocusController;

import {
  createContext,
  useEffect,
  FC,
  useReducer,
  Dispatch,
  ReactChild,
} from "react";
import { shuffle } from "lodash";
import MainScene from "../experience/MainScene";
import WebGLView from "../experience/WebGLView";
import useSocketSync from "../util/useSocketSync";
import resourcesData from "../config/resources.json";
import {
  canGoToReview,
  formatChainBalanceSummary,
  getCharacterName,
  mustGoToReview,
} from "../util/helpers";
import {
  Mode,
  FlowState,
  TResource,
  InventoryBuildState,
  ResourceType,
  TChainBalanceSummaryItem,
  ReviewState,
  Action,
  ActionType,
  TFocusedResource,
  TInventoryItem,
} from "./types";
import { CHARACTER_ORDER } from "../config/config";

// isBalanced: [...inventorySummary.values()].every(
//   ({ isBalanced }) => isBalanced
// ),
// chainBalance,
// chainBalanceTotalItems,
// summary: inventorySummary,
// totalInventoryItems,
// totalConnections,

export interface AppState {
  webGlView: WebGLView;
  mainScene: MainScene;
  socket: any;
  connectionKey: string;
  isControllerConnected: null | number;
  mode: Mode;
  flowState: FlowState;
  characterResource: TResource | null;
  characterType: ResourceType;
  characterName: string | null;
  characterNameShort: string | null;
  showCharacterImage: boolean;
  inventoryBuildState: InventoryBuildState | null;
  inventory: TInventoryItem[];
  chainBalanceSummary: {
    isBalanced: boolean;
    chainBalance: any;
    chainBalanceTotalItems: number;
    summary: Map<ResourceType, TChainBalanceSummaryItem>;
    totalInventoryItems: number;
    totalConnections: number;
  } | null;
  cachedResourceConnections: Map<string, string[]>;
  reviewState: ReviewState | null;
  focusedResource: TFocusedResource | null;
  showResetWarning: boolean;
  showTabletHint: boolean;
  isInternetDisconnected: boolean;
}

const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case ActionType.OnWebGlInit:
      return { ...state, webGlView: action.value };
    case ActionType.OnMainSceneInit:
      return { ...state, mainScene: action.value };
    case ActionType.SetSocket:
      return { ...state, socket: action.value };
    case ActionType.JoinedRoom:
      return {
        ...state,
        connectionKey: action.value,
        isControllerConnected: state.mode === Mode.Main ? false : null,
      };
    case ActionType.OnControllerConnectionChange:
      return {
        ...state,
        isControllerConnected: action.value,
      };
    case ActionType.Start:
      return { ...state, flowState: FlowState.ChooseCharacter };
    case ActionType.PickCharacterType:
      if (action.value.confirm) {
        // pick a random resource with the user-selected type.
        let characterResource;
        const randomizedArray = shuffle(resourcesData.resources);
        for (let i = 0; i < randomizedArray.length; i++) {
          if (randomizedArray[i].type === action.value.type) {
            characterResource = randomizedArray[i];
            break;
          }
        }

        const { name, nameShort } = getCharacterName(action.value.type);
        return {
          ...state,
          flowState: FlowState.Build,
          characterResource,
          characterType: action.value.type,
          characterName: name,
          characterNameShort: nameShort || name, // not all have a short name
          inventory: [],
          chainBalanceSummary: formatChainBalanceSummary(action.value.type, []),
          inventoryBuildState: InventoryBuildState.Onboarding,
        };
      } else {
        return {
          ...state,
          characterType: action.value.type,
        };
      }
    case ActionType.OnSyncState:
      if (action.value.cachedResourceConnections) {
        action.value.cachedResourceConnections = new Map(
          JSON.parse(action.value.cachedResourceConnections)
        );
      }
      if (action.value.chainBalanceSummary) {
        action.value.chainBalanceSummary.summary = new Map(
          JSON.parse(action.value.chainBalanceSummary.summary)
        );
      }
      return {
        ...state,
        ...action.value,
        isControllerConnected:
          state.mode === Mode.Main ? true : state.isControllerConnected,
      };
    case ActionType.Reset:
      return {
        ...state,
        flowState: FlowState.ChooseCharacter,
        characterResource: null,
        characterName: null,
        characterNameShort: null,
        characterType:
          state.mode === Mode.Controller
            ? CHARACTER_ORDER[
                Math.floor(Math.random() * CHARACTER_ORDER.length)
              ]
            : state.characterType,
        showCharacterImage: false,
        inventory: [],
        inventoryBuildState: null,
        chainBalanceSummary: null,
        reviewState: null,
        showResetWarning: false,
        showTabletHint: false,
      };
    case ActionType.SelectResource:
      const newInventory1 = [...state.inventory, action.value];
      if (!state.characterResource) return { ...state }; // bug fix
      return {
        ...state,
        chainBalanceSummary: formatChainBalanceSummary(
          state.characterResource.type,
          newInventory1
        ),
        inventory: newInventory1,
      };
    case ActionType.DeselectResource:
      const newInventory = state.inventory.filter(
        ({ _id }) => _id !== action.value
      );
      if (!state.characterResource) return { ...state }; // bug fix
      return {
        ...state,
        chainBalanceSummary: formatChainBalanceSummary(
          state.characterResource.type,
          newInventory
        ),
        inventory: newInventory,
      };
    case ActionType.SetInventoryBuildState:
      return {
        ...state,
        inventoryBuildState: action.value,
      };
    case ActionType.OnPickingExtra:
      return {
        ...state,
        inventoryBuildState: InventoryBuildState.PickingExtra,
      };
    case ActionType.OnFinishedPicking:
      const chainBalanceSummary = formatChainBalanceSummary(
        state.characterResource.type,
        state.inventory
      );
      const { isBalanced } = chainBalanceSummary;
      return {
        ...state,
        flowState: isBalanced ? FlowState.Finish : FlowState.Review,
        showCharacterImage: false,
        reviewState: isBalanced ? ReviewState.Balanced : ReviewState.Unbalanced,
        inventoryBuildState: null,
        chainBalanceSummary,
      };
    case ActionType.OnReviewed:
      const reviewState =
        action.value === false
          ? ReviewState.RefusedRebalance
          : action.value === true
          ? ReviewState.AcceptedRebalance
          : ReviewState.Balanced;

      // if (reviewState === ReviewState.Balanced) {
      //   setResourceConnectionMap(
      //     state.cachedResourceConnections,
      //     state.characterResource._id,
      //     state.inventory
      //   );
      // }

      return {
        ...state,
        flowState: FlowState.Finish,
        reviewState,
      };
    case ActionType.OnRebalanced:
      // setResourceConnectionMap(
      //   state.cachedResourceConnections,
      //   state.characterResource._id,
      //   state.inventory
      // );
      return { ...state, reviewState: ReviewState.CompletedRebalance };
    case ActionType.SetFocusedResource:
      return {
        ...state,
        focusedResource: action.value,
      };
    case ActionType.ShowResetWarning:
      return {
        ...state,
        showResetWarning: action.value,
      };
    case ActionType.ShowTabletHint:
      return {
        ...state,
        showTabletHint: action.value,
      };
    case ActionType.ShowTabletHint:
      return {
        ...state,
        showTabletHint: action.value,
      };
    case ActionType.ShowCharacterImage:
      return {
        ...state,
        showCharacterImage: true,
      };

    case ActionType.SetConnectionIsOffline:
      return {
        ...state,
        isInternetDisconnected: action.value,
      };
    default:
      return state;
  }
};

const initialState: AppState = {
  socket: null,
  webGlView: null,
  mainScene: null,
  connectionKey: null,
  isControllerConnected: null,
  mode: Mode.Main,
  flowState: FlowState.ChooseCharacter,
  characterResource: null,
  characterName: null,
  characterNameShort: null,
  characterType:
    CHARACTER_ORDER[Math.floor(Math.random() * CHARACTER_ORDER.length)],
  showCharacterImage: false,
  inventoryBuildState: null,
  inventory: [],
  chainBalanceSummary: null,
  // cachedResourceConnections: new Map(
  //   JSON.parse(
  //     window.localStorage.getItem(CACHED_CONNECTIONS_LOCAL_STORAGE_KEY)
  //   )
  // ),
  cachedResourceConnections: new Map(),
  focusedResource: null,
  reviewState: null,
  showResetWarning: false,
  showTabletHint: false,
  isInternetDisconnected: !navigator.onLine,
};

export const AppContext = createContext<{
  state?: AppState;
  dispatch?: Dispatch<Action>;
}>({ state: {} as AppState, dispatch: () => null });

export const AppProvider: FC<{
  children: ReactChild;
  connectionKey?: string;
  connectAsDev?: boolean;
  mode: Mode;
}> = ({ children, connectionKey, connectAsDev, mode }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    mode,
  });
  const { inventory, inventoryBuildState, flowState, characterResource } =
    state;
  useEffect(() => {
    (window as any).dispatch = dispatch;
  }, []);

  useSocketSync(state, dispatch, connectionKey, connectAsDev);

  useEffect(() => {
    if (!characterResource) return;
    if (!inventoryBuildState) return;
    if (flowState !== FlowState.Build) return;
    if (inventoryBuildState === InventoryBuildState.Onboarding) return;

    if (mustGoToReview(inventory)) {
      dispatch({
        type: ActionType.SetInventoryBuildState,
        value: InventoryBuildState.PickedMaximum,
      });
      return;
    }

    if (
      canGoToReview(characterResource.type, inventory) &&
      inventoryBuildState !== InventoryBuildState.PickingExtra
    ) {
      dispatch({
        type: ActionType.SetInventoryBuildState,
        value: InventoryBuildState.PickedEnough,
      });
      return;
    }

    if (!canGoToReview(characterResource.type, inventory)) {
      dispatch({
        type: ActionType.SetInventoryBuildState,
        value: InventoryBuildState.NotEnough,
      });
      return;
    }
  }, [inventory?.length, inventoryBuildState, flowState, characterResource]);

  useEffect(() => {
    window.addEventListener("offline", () => {
      dispatch({ type: ActionType.SetConnectionIsOffline, value: true });
    });

    window.addEventListener("online", () => {
      dispatch({ type: ActionType.SetConnectionIsOffline, value: false });
    });
  }, []);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

import { Group, Mesh } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

const SPRING = 0.018;
const DAMPING = 0.85;

class TakeHomeResourceMesh extends Group {
  private scaleVelocity = 0;
  private targetScale = 1;
  private currentScale = 1;
  private ignoreBounceScale = false;
  private chainReviewIndex: number = null;

  constructor(model: GLTF, chainReviewIndex) {
    super();
    this.chainReviewIndex = chainReviewIndex;
    model.scene.traverse((child) => {
      if (child instanceof Mesh) {
        const meshClone = new Mesh(child.geometry, child.material);
        meshClone.position.copy(child.position);
        meshClone.rotation.copy(child.rotation);
        meshClone.scale.copy(child.scale);
        meshClone.userData.material = child.material;
        this.add(meshClone);
        this.userData.rotationSpeed = 1 + Math.random();
      }
    });
    this.onChainItemAudio = this.onChainItemAudio.bind(this);

    window.addEventListener("chain-item-audio", this.onChainItemAudio);
  }

  public onChainItemAudio({ detail }) {
    if (detail === this.chainReviewIndex) {
      this.doBounceScaleAnimation();
    }
  }

  public doBounceScaleAnimation() {
    if (this.ignoreBounceScale === true) return;

    const previousTargetScale = this.targetScale;
    this.ignoreBounceScale = true;
    this.targetScale = previousTargetScale * 1.33;
    setTimeout(() => {
      this.ignoreBounceScale = false;
      this.targetScale = previousTargetScale;
    }, 444);
  }

  public update(correction = 1) {
    this.scaleVelocity +=
      (this.targetScale - this.currentScale) * SPRING * correction;
    this.scaleVelocity *= DAMPING;
    this.currentScale += this.scaleVelocity;
    this.scale.set(this.currentScale, this.currentScale, this.currentScale);
  }
}
export default TakeHomeResourceMesh;

import "./app.scss";
import { FC, useEffect } from "react";
import { ResourceType, TResource } from "./contexts/types";
import UIAudio from "./UIAudio";
import TakeHome from "./layouts/TakeHome";

const uiAudio = new UIAudio(false, true, false);

const AppTakeHome: FC<{ chain: TResource[]; name }> = ({ chain, name }) => {
  return (
    <TakeHome
      chain={chain}
      name={name}
      playChain={() => {
        uiAudio.isMuted = false;
        if (chain && name) {
          const chainPlaylist = [
            ...chain.map(({ type }) => ResourceType[type]),
          ];
          uiAudio?.playChain(chainPlaylist);
        }
      }}
    />
  );
};

export default AppTakeHome;

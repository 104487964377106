import {
  Scene,
  sRGBEncoding,
  Vector3,
  WebGLRenderer,
  PerspectiveCamera,
  Mesh,
  SphereBufferGeometry,
  TextureLoader,
  RepeatWrapping,
  OrthographicCamera,
  SpriteMaterial,
  DoubleSide,
  Sprite,
} from "three";
import { FlowState, TInventoryItem } from "../contexts/types";
import { setTextureProperties } from "../util/helpers";
import gradientMapSrc from "url:../maps/gradient-map-controller.png";
import SkyShaderMaterial from "./Materials/SkyShaderMaterial/SkyShader";
import FloatyParticlesSprites from "./FloatyParticlesSprites";
import minimapBgSrc from "url:../maps/minimap-bg.png";

export const SKYBOX_RADIUS = Math.max(window.innerWidth, window.innerHeight);

const CAMERA_POSITION = new Vector3(0, 0, -SKYBOX_RADIUS * 0.5);

const CAMERA_SIZE = 0.5;

class ControllerBgScene extends Scene {
  public sky: Mesh;
  public camera = new OrthographicCamera(
    -window.innerWidth / 2,
    window.innerWidth / 2,
    window.innerHeight / 2,
    -window.innerHeight / 2,
    0.1,
    SKYBOX_RADIUS * 2
  );
  private floatyParticles = new FloatyParticlesSprites(
    SKYBOX_RADIUS,
    70,
    70,
    "flat"
  );
  private _flowState: FlowState;
  private _inventory: TInventoryItem[] = [];

  constructor() {
    super();

    this.camera.position.copy(CAMERA_POSITION);
    this.add(this.camera);
    this.camera.lookAt(this.position);

    const gradientMap = new TextureLoader().load(gradientMapSrc);
    gradientMap.repeat.x = gradientMap.repeat.y = RepeatWrapping;
    gradientMap.encoding = sRGBEncoding;
    this.sky = new Mesh(
      new SphereBufferGeometry(SKYBOX_RADIUS),
      new SkyShaderMaterial({
        gradientMap,
        gradientMapCount: 8,
        gradientMapIndex: 0,
        hasSun: false,
      })
    ).rotateX(Math.PI * 0.5);
    this.add(this.sky);
    this.sky.rotation.x = Math.PI * 0.5;
    this.add(this.floatyParticles);
  }

  public async init(renderer: WebGLRenderer): Promise<void> {
    const minimapBg = await new TextureLoader().loadAsync(minimapBgSrc);
    minimapBg.flipY = false;
    const test = new Sprite(
      new SpriteMaterial({
        map: minimapBg,
        transparent: true,
        side: DoubleSide,
      })
    );
    test.scale.set(minimapBg.image.width, minimapBg.image.height, 1);
    // this.add(test);
  }

  public set inventory(inventory: TInventoryItem[]) {
    this._inventory = inventory;

    requestAnimationFrame(() => {
      this.sky.material.changeGradient(this.flowState);
    });
  }

  public get inventory() {
    return this._inventory;
  }

  public set flowState(flowState: FlowState) {
    this._flowState = flowState;
    this.sky.material.changeGradient(this.flowState);
  }

  public get flowState() {
    return this._flowState;
  }

  public update(correction = 1): void {
    this.floatyParticles.update(correction);
    this.sky.material.update(correction);
    this.sky.rotation.z += -0.002 * correction;
  }

  public onResize(width: number, height: number) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }

  public destroy(): void {}
}

export default ControllerBgScene;

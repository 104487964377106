const _temp0 = require("url:./01-Settling.mp3");
const _temp1 = require("url:./02-Character-Selection_Next.mp3");
const _temp2 = require("url:./02-Character-Selection_Previous.mp3");
const _temp3 = require("url:./03-Dive.mp3");
const _temp4 = require("url:./04-Hover_Bird.mp3");
const _temp5 = require("url:./04-Hover_Fungi.mp3");
const _temp6 = require("url:./04-Hover_Glacier.mp3");
const _temp7 = require("url:./04-Hover_Human.mp3");
const _temp8 = require("url:./04-Hover_Mountain.mp3");
const _temp9 = require("url:./04-Hover_River.mp3");
const _temp10 = require("url:./04-Hover_Tree.mp3");
const _temp11 = require("url:./05-Pick.mp3");
const _temp12 = require("url:./06-Prompt.mp3");
const _temp13 = require("url:./07-Chain.mp3");
module.exports = {
  "01-Settling": _temp0,
  "02-Character-Selection_Next": _temp1,
  "02-Character-Selection_Previous": _temp2,
  "03-Dive": _temp3,
  "04-Hover_Bird": _temp4,
  "04-Hover_Fungi": _temp5,
  "04-Hover_Glacier": _temp6,
  "04-Hover_Human": _temp7,
  "04-Hover_Mountain": _temp8,
  "04-Hover_River": _temp9,
  "04-Hover_Tree": _temp10,
  "05-Pick": _temp11,
  "06-Prompt": _temp12,
  "07-Chain": _temp13
}
.root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  user-select: none !important;
  -webkit-user-select: none !important;
}

.root * {
  user-select: none !important;
  -webkit-user-select: none !important;
}

.pad {
  width: 100%;
  height: 100%;
  position: absolute;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pad > button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: transparent;
  appearance: none;
  border: none;

  @media (hover: hover) {
    display: none;
  }
}

.touch {
  position: absolute;
  width: 12rem;
  height: 12rem;
  opacity: 0;
  transition: opacity 0.1s ease;
}

.touch:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12rem;
  height: 12rem;
  border: 10px solid var(--color);
  border-radius: 11111px;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 10px var(--color))
    drop-shadow(0px 0px 10px var(--color));
  transition: all 0.1s ease;
}

.touch--is-visible {
  opacity: 1;
  transition-delay: 0.1s;
}

.move {
  position: absolute;
  width: 16rem;
  height: 16rem;
  margin-top: -8rem;
  margin-left: -8rem;
  opacity: 0;
  transition: all 0.1s ease;
}

.move > svg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 0px 10px var(--color));
  transition: all 0.1s ease;
}

.move--is-visible {
  opacity: 1;
}

.move:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8rem;
  height: 8rem;
  border: 10px solid var(--color);
  border-radius: 1111px;
  filter: drop-shadow(0px 0px 10px var(--color));
  transition: all 0.1s ease;
  transform: translate(-50%, -50%);
}

.move:after,
.touch:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  background: var(--color);
  border-radius: 111px;
  box-shadow: 0px 0px 10px var(--color);
  transition: all 0.1s ease;
  opacity: 0;
}

.touch:after {
  width: 8rem;
  height: 8rem;
}

.move:after {
  width: 5rem;
  height: 5rem;
}

.move--can-click:after {
  opacity: 1;
}

.touch--can-click:after {
  opacity: 1;
}

import * as classes from "./main-canvas.module.scss";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../contexts/AppContext";
import {
  ActionType,
  FlowState,
  InventoryBuildState,
} from "../../contexts/types";
import MainScene from "../../experience/MainScene";
import WebGLView from "../../experience/WebGLView";
import classnames from "classnames";
import { Vector2 } from "three";

const ZERO_V2 = new Vector2();

const MainCanvas = () => {
  const { dispatch, state } = useContext(AppContext);
  const {
    mainScene,
    flowState,
    inventoryBuildState,
    reviewState,
    inventory,
    cachedResourceConnections,
    characterResource,
    characterType,
    webGlView,
  } = state;
  const canvasRef = useRef();
  const joystickResetTO = useRef<number>();

  useEffect(() => {
    const init = async () => {
      const webGlView = new WebGLView(canvasRef.current);
      await webGlView.init();
      dispatch({ type: ActionType.OnWebGlInit, value: webGlView });
      const scene = new MainScene(webGlView.renderer);
      await scene.init();
      webGlView.setScene(scene);
      scene.cachedResourceConnections = cachedResourceConnections;
      dispatch({ type: ActionType.OnMainSceneInit, value: scene });
    };
    init();
  }, []);

  useEffect(() => {
    if (!mainScene) return;
    mainScene.characterResource = characterResource;
  }, [mainScene, characterResource]);

  useEffect(() => {
    if (!mainScene) return;
    mainScene.characterType = characterType;
  }, [mainScene, characterType]);

  useEffect(() => {
    if (!mainScene) return;

    mainScene.flowState = flowState;
  }, [mainScene, flowState]);

  useEffect(() => {
    if (
      mainScene &&
      flowState === FlowState.Build &&
      (inventoryBuildState === InventoryBuildState.NotEnough ||
        inventoryBuildState === InventoryBuildState.PickingExtra)
    ) {
      mainScene.isEnventoryPickingEnabled = true;
    } else if (mainScene) {
      mainScene.isEnventoryPickingEnabled = false;
    }

    if (
      mainScene &&
      flowState === FlowState.Build &&
      inventoryBuildState > InventoryBuildState.Onboarding
    ) {
      mainScene.isOnboardingFinished = true;
    } else if (
      mainScene &&
      (flowState < FlowState.Build ||
        inventoryBuildState === InventoryBuildState.Onboarding)
    ) {
      mainScene.isOnboardingFinished = false;
    }
  }, [mainScene, flowState, inventoryBuildState]);

  useEffect(() => {
    if (!mainScene) return;

    if (inventoryBuildState === InventoryBuildState.Onboarding) return;

    const onJoystickValue = (data) => {
      clearTimeout(joystickResetTO.current);
      // in case the websocket connection is dodgy, set the joystick value to zero if no message for a while so it doesn't get stuck on.
      joystickResetTO.current = setTimeout(() => {
        mainScene.joystickValue = ZERO_V2;
      }, 999);

      if (data) {
        mainScene.joystickValue = data;
      }
    };
    state.socket.on("joystick", onJoystickValue);

    return () => {
      state.socket.off("joystick", onJoystickValue);
    };
  }, [mainScene, inventoryBuildState]);

  useEffect(() => {
    if (!webGlView) return;

    webGlView.renderMinimap =
      flowState === FlowState.Build &&
      inventoryBuildState > InventoryBuildState.Onboarding;
  }, [webGlView, inventoryBuildState, flowState]);

  // inventory was updated
  useEffect(() => {
    if (!mainScene) return;
    mainScene.inventory = inventory;
    mainScene.drawConnectionsForInventory(inventory, reviewState !== null);
  }, [mainScene, inventory?.length, reviewState]);

  // cached resource connections was updated
  useEffect(() => {
    if (!mainScene) return;
    mainScene.cachedResourceConnections = cachedResourceConnections;
  }, [mainScene, cachedResourceConnections.size]);

  // on reset
  useEffect(() => {
    if (!mainScene) return;
    if (flowState === FlowState.Idle) {
      mainScene.inventory = inventory;
      mainScene.cachedResourceConnections = cachedResourceConnections;
    }
  }, [mainScene, flowState]);

  return (
    <div
      className={classnames(classes.root, {
        [classes["root--disabled"]]:
          inventoryBuildState === InventoryBuildState.Onboarding ||
          inventoryBuildState === InventoryBuildState.PickedEnough ||
          flowState === FlowState.Idle ||
          flowState === FlowState.Review ||
          flowState === FlowState.Finish,
      })}
    >
      <canvas ref={canvasRef} className={classes.canvas}></canvas>
    </div>
  );
};

export default MainCanvas;

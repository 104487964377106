precision mediump float;

uniform float uDrawProgress;
uniform float uTravelDist;

varying float vOpacity;
attribute float linepct;
attribute float dist;

void main() {
  vOpacity = 1.0 - step(uDrawProgress, linepct);
  // vOpacity = clamp(1.0 - abs(uTravelDist - dist), 0.0, 1.0);

  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
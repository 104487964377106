import {
  BufferGeometry,
  CubicBezierCurve3,
  Float32BufferAttribute,
  Line,
  MathUtils,
  Object3D,
  ShaderMaterial,
  Vector3,
} from "three";
import Easing from "easing-functions";

import { clamp } from "three/src/math/MathUtils";
import ResourceConnectionMaterial from "./Materials/ResourceConnectionMaterial/ResourceConnectionMaterial";
import ResourceMesh from "./ResourceMesh";

const TMP_V3 = new Vector3();

const DRAW_ON_DURATION = 1000;

class ResourceConnection extends Line {
  private curve = new CubicBezierCurve3(
    new Vector3(),
    new Vector3(),
    new Vector3(),
    new Vector3()
  );
  private from: Object3D;
  private to: Object3D;
  private previousFromPosition = new Vector3();
  private previousToPosition = new Vector3();
  private cpOffset = new Vector3();
  private attribuesNeedUpdate = true;
  private drawOnTime = 0;
  private length = 1;
  private speed = 1;
  public isPending: boolean;

  constructor(from: Object3D, to: Object3D, isPending = false, speed = 1) {
    super(
      new BufferGeometry(),
      new ResourceConnectionMaterial({ color: isPending ? 0xffffff : 0xffffff })
    );
    this.speed = speed;
    this.from = from;
    this.to = to;
    this.previousFromPosition.copy(this.from.position);
    this.previousToPosition.copy(this.to.position);
    this.frustumCulled = false;

    this.isPending = isPending;

    this.cpOffset
      .set(
        MathUtils.randFloatSpread(1),
        MathUtils.randFloatSpread(1),
        MathUtils.randFloatSpread(1)
      )
      .normalize();

    this.name =
      from instanceof ResourceMesh && to instanceof ResourceMesh
        ? `${from.data._id}_${to.data._id}`
        : `connection_${Math.random() * 99999}`;
    this.layers.enable(1);
    this.updateGeometry();
    this.matrixAutoUpdate = false;
  }

  public updateGeometry() {
    this.curve.v0.copy(this.from.position);
    this.curve.v3.copy(this.to.position);

    const dist = this.curve.v0.distanceTo(this.curve.v3);

    this.curve.v1.lerpVectors(this.curve.v0, this.curve.v3, 0.33);
    this.curve.v2.lerpVectors(this.curve.v3, this.curve.v0, 0.33);

    // direction of to > from
    TMP_V3.copy(this.curve.v3).sub(this.curve.v0).normalize();
    // cross the offset with the direction of the line
    TMP_V3.crossVectors(TMP_V3, this.cpOffset);
    // scale the offset by the length of the line
    TMP_V3.multiplyScalar(dist * 0.25);
    // add / sub the offset to the control point
    this.curve.v1.add(TMP_V3);
    this.curve.v2.sub(TMP_V3);

    this.geometry.setFromPoints(this.curve.getPoints(Math.round(dist * 10)));

    // we only use these attributes when the line is drawing on so don't bother setting them otherwise
    if (this.attribuesNeedUpdate) {
      this.length = this.curve.getLength();
      (this.material as ShaderMaterial).uniforms.uLength.value = length;

      const linepct = [];
      const distances = [];
      for (let i = 1; i <= this.geometry.attributes.position.count; i++) {
        const pc = i / this.geometry.attributes.position.count;
        linepct.push(pc);
        distances.push(this.length * pc);
      }

      // is this slow?
      this.geometry.setAttribute(
        "linepct",
        new Float32BufferAttribute(linepct, 1)
      );
      this.geometry.setAttribute(
        "dist",
        new Float32BufferAttribute(distances, 1)
      );
    }
  }

  public updateDrawOn(correction) {
    this.drawOnTime += 16.666 * correction;
    const drawOnPct = clamp(this.drawOnTime / DRAW_ON_DURATION, 0, 1);
    (this.material as ShaderMaterial).uniforms.uDrawProgress.value =
      Easing.Quintic.InOut(drawOnPct);
  }

  public update(correction): void {
    (this.material as ShaderMaterial).uniforms.uTravelDist.value +=
      0.1 * this.speed * correction;
    (this.material as ShaderMaterial).uniforms.uTravelDist.value =
      (this.material as ShaderMaterial).uniforms.uTravelDist.value %
      this.length;

    this.visible = this.from.visible && this.to.visible;

    if (
      this.from.position.distanceTo(this.previousFromPosition) > 0 ||
      this.to.position.distanceTo(this.previousToPosition) > 0
    ) {
      this.updateGeometry();
    }

    this.previousFromPosition.copy(this.from.position);
    this.previousToPosition.copy(this.to.position);

    if (this.drawOnTime < DRAW_ON_DURATION) {
      this.updateDrawOn(correction);
    } else {
      (this.material as ShaderMaterial).uniforms.uDrawProgress.value = 1;
    }
  }
}

export default ResourceConnection;

import { Mesh, Object3D } from "three";
import { orderBy } from "lodash";
import {
  CharacterNamesDict,
  INTD_PROVOCATIONS,
  MARGIN_OF_ERROR,
  ResourceChainBalances,
} from "../config/config";
import {
  ResourceType,
  Sizes,
  TChainBalanceSummaryItem,
  TInventoryItem,
  TResource,
} from "../contexts/types";
import { shuffle } from "lodash";

// encodes the inventory json as a numeric string
export const encodeInventory = (inventory) => {
  return inventory.reduce((carry, { quantity, type }) => {
    return `${carry}${carry === "" ? "" : ","}${type}${Sizes[quantity]}`;
  }, "");
};

export const decodeInventory: (inventory: string) => TResource[] = (
  inventory
) => {
  if (!inventory) return null;
  const items = inventory.split(",");
  return items.map((item, i) => {
    return {
      _id: String(i),
      type: parseInt(item.substr(0, 1)),
      quantity: Sizes[item.substr(1)],
    };
  });
};

export const encodeName: (type: ResourceType, name: string) => string = (
  type,
  name
) => {
  const namesDict = CharacterNamesDict.get(type);
  const index = namesDict.findIndex(({ name: _name }) => _name === name);

  return `${type},${index}`;
};

export const decodeName: (name: string) => string = (name) => {
  if (!name) return null;
  const segs = name.split(",");
  const namesDict = CharacterNamesDict.get(parseInt(segs[0]));
  const index = parseInt(segs[1]);
  return namesDict[index].name;
};

export const setTextureProperties = (child: Object3D, properties) => {
  child.traverse((child: Object3D) => {
    if (child instanceof Mesh) {
      for (const key of Object.keys(child.material)) {
        const value = child.material[key];
        if (value && typeof value === "object" && "minFilter" in value) {
          Object.keys(properties).forEach((propertyKey) => {
            value[propertyKey] = properties[propertyKey];
          });
        }
      }
    }
  });
};

export const getCharacterName = (type: ResourceType) => {
  const possibleNames = CharacterNamesDict.get(type);
  return possibleNames[Math.floor(Math.random() * possibleNames.length)];
};

export const buildInventorySummary = (inventory: TInventoryItem[]) => {
  const summaryItems = {};
  inventory.forEach(({ type, quantity }) => {
    if (summaryItems[type]) {
      summaryItems[type] = summaryItems[type] + quantity;
    } else {
      summaryItems[type] = quantity;
    }
  });
  return summaryItems;
};

export const canGoToReview = (
  type: ResourceType,
  inventory: TInventoryItem[]
) => {
  const chainBalance = ResourceChainBalances.get(type);
  const inventoryTotals = buildInventorySummary(inventory);

  for (let i = 0; i < chainBalance.length; i++) {
    const { type, quantity } = chainBalance[i];
    if (inventoryTotals[type] === undefined && quantity > 0) {
      return false;
    }
  }

  return true;
};

export const mustGoToReview = (inventory: TInventoryItem[]) => {
  return inventory.length >= 15;
};

export const formatChainBalanceSummary = (
  type: ResourceType,
  inventory: TInventoryItem[]
) => {
  const totalConnections = inventory.reduce(
    (carry, { connectionsCount }) => carry + connectionsCount,
    0
  );
  // The ideal scenareo for chain balance
  const chainBalance = ResourceChainBalances.get(type);
  const chainBalanceTotalItems = chainBalance.reduce(
    (carry, { quantity }) => carry + quantity,
    0
  );

  // What the user has selected
  const inventoryTotals = buildInventorySummary(inventory);
  const totalInventoryItems = inventory.reduce(
    (carry, { quantity }) => carry + quantity,
    0
  );

  const inventorySummary = new Map<ResourceType, TChainBalanceSummaryItem>();

  for (let i = 0; i < chainBalance.length; i++) {
    const { quantity: target, type } = chainBalance[i];
    // What quantity of this resource type is ideal for the chain, and what is our allowable margin or error.
    // const chainBalanceFract = quantity / chainBalanceTotalItems;
    const acceptableRange =
      target === 0 ? 0 : Math.ceil(target * MARGIN_OF_ERROR + 1);
    const min = target - acceptableRange;
    const max = target + acceptableRange;

    // Hasn't collected any of a required type
    if (inventoryTotals[type] === undefined) {
      inventorySummary.set(type, {
        type: ResourceType[type],
        target,
        min,
        max,
        current: 0,
        isBalanced: false,
        requiresMore: true,
        requiresFewer: false,
      });
      continue;
    }

    // What quantity of this resource type has the user collected in their chain
    const current = inventoryTotals[type];

    // is in acceptable range?
    const isTypeSatisfied = current >= min && current <= max;

    inventorySummary.set(type, {
      type: ResourceType[type],
      target,
      min,
      max,
      current,
      isBalanced: isTypeSatisfied,
      requiresMore: current < min,
      requiresFewer: current > max,
    });
  }

  return {
    isBalanced: [...inventorySummary.values()].every(
      ({ isBalanced }) => isBalanced
    ),
    chainBalance,
    chainBalanceTotalItems,
    summary: inventorySummary,
    totalInventoryItems,
    totalConnections,
  };
};

// for now just don't bother rebalancing

type TAddAction = { action: "add"; quantity: number; type: ResourceType };
type TRemoveAction = { action: "remove"; _id: string };
type TRebalanceActions = (TAddAction | TRemoveAction)[];

export const getRebalanceInventoryActions = (
  inventory: TInventoryItem[],
  inventorySummary: Map<ResourceType, TChainBalanceSummaryItem>
): TRebalanceActions => {
  const rebalanceActions: TRebalanceActions = [];

  inventorySummary.forEach(
    (
      { current, target, min, max, isBalanced, requiresMore, requiresFewer },
      type
    ) => {
      if (isBalanced) {
        console.log(`${ResourceType[type]} is balanced`);
        return;
      }
      // if type is not required in chain, remove them all...
      if (target === 0) {
        console.log(`Type not required ${ResourceType[type]}`);
        const toRemove = inventory.filter(({ type: itemType }) => {
          return itemType === type;
        });
        rebalanceActions.push(
          ...(toRemove.map(({ _id }) => ({
            action: "remove",
            _id,
          })) as TRemoveAction[])
        );
        return;
      }

      // If we have too many in the chain, remove them starting with the largest quantity, until reach below the max level or only have one item of type left
      if (requiresFewer) {
        let removals = 0;

        console.log(
          `Requires fewer ${ResourceType[type]} current ${current}, max: ${max}`
        );

        const candidatesToRemove = orderBy(
          inventory.filter(({ type: itemType }) => {
            return itemType === type;
          }),
          ["quantity"],
          ["desc"]
        );

        let i = 0;
        // we never remove the smallest amount of a balance
        while (current - removals > max && i < candidatesToRemove.length - 1) {
          const candidate = candidatesToRemove[i];
          removals += candidate.quantity;
          rebalanceActions.push({ action: "remove", _id: candidate._id });
          i++;
        }
        return;
      }

      // If we have too few, add more items until we have an acceptable amount in the chain
      if (requiresMore) {
        console.log(
          `Requires more ${ResourceType[type]} current ${current}, min: ${min}`
        );

        let additions = 0;

        while (current + additions < min) {
          if (current + additions + 15 <= max) {
            console.log(`Add 15 more ${ResourceType[type]}`);
            additions += 15;
            rebalanceActions.push({ action: "add", quantity: 15, type });
          } else if (current + additions + 5 <= max) {
            console.log(`Add 5 more ${ResourceType[type]}`);
            additions += 5;
            rebalanceActions.push({ action: "add", quantity: 5, type });
          } else if (current + additions + 1 <= max) {
            console.log(`Add 1 more ${ResourceType[type]}`);
            additions += 1;
            rebalanceActions.push({ action: "add", quantity: 1, type });
          }
        }
        return;
      }
    }
  );

  return rebalanceActions;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Iterators_and_Generators
export function* makeRebalanceIterator(actions: TRebalanceActions) {
  for (let i = 0; i < actions.length; i++) {
    yield actions[i];
  }
  return true;
}

export const setResourceConnectionMap = (
  map: Map<string, string[]>,
  characterResourceId: string,
  inventory: TInventoryItem[]
) => {
  const setConnection = (from: string, to: string) => {
    const connections = map.get(from) || [];
    if (connections.indexOf(to) === -1) {
      connections.push(to);
      map.set(from, connections);
    }
  };

  setConnection(characterResourceId, inventory[0]._id);

  for (let i = 0; i < inventory.length; i++) {
    setConnection(
      inventory[i]._id,
      inventory[i + 1]?._id || characterResourceId
    );
  }

  return map;
};

export const getProvocation = (type: ResourceType) => {
  const randomised = shuffle(INTD_PROVOCATIONS);
  for (let i = 0; i < randomised.length; i++) {
    if (randomised[i].types.indexOf(type) > -1) {
      return randomised[i].text;
    }
  }
};

const _temp0 = require("url:./Bird.mp3");
const _temp1 = require("url:./Fungi.mp3");
const _temp2 = require("url:./Glacier.mp3");
const _temp3 = require("url:./Human.mp3");
const _temp4 = require("url:./Mountain.mp3");
const _temp5 = require("url:./River.mp3");
const _temp6 = require("url:./Tree.mp3");
module.exports = {
  "Bird": _temp0,
  "Fungi": _temp1,
  "Glacier": _temp2,
  "Human": _temp3,
  "Mountain": _temp4,
  "River": _temp5,
  "Tree": _temp6
}
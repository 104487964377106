@import "../../scss/variables.scss";

.root {
  background: $colour--yellow;
  @include toVw(font-size, 80, 1.25rem);
  height: 1.4em;
  padding: 0 0.35em;
  border-radius: 1111px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.root--yellow {
  color: #000;
  background: $colour--yellow;
  box-shadow: 0px 0px 30px $colour--yellow;
}

.root--blue {
  background: $colour--blue;
  box-shadow: 0px 0px 30px $colour--blue;
}

.type {
  @include toVw(margin-right, 16);
  line-height: 1em;
}

.quantity {
  border: 2px solid black;
  border-radius: 50%;
  @include toVw(font-size, 40);
  width: 1.5em;
  height: 1.5em;
  line-height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  0% {
    transform: scale(1);
  }
}

.root--bounce {
  animation: bounce 0.5s ease-in-out;
}

.root > svg {
  height: 60%;
  width: auto;
  margin-right: 0.3em;
}

.root > svg path {
  fill: #000;
}

import { PerspectiveCamera, Vector3 } from "three";

const SPRING = 0.1;
const DAMPING = 0.2;

const TMP_V3 = new Vector3();

class AnimatedCamera extends PerspectiveCamera {
  private targetPosition = new Vector3();
  private naturalToTargetPosition = new Vector3();
  private positionVelocity = new Vector3();
  public forward = new Vector3();

  constructor(...args) {
    super(...args);
    this.rotation.order = "YXZ";
  }

  public set viewPosition(position: Vector3) {
    this.targetPosition.copy(position);
  }

  public update(correction = 1) {
    this.getWorldDirection(this.forward);
    this.positionVelocity.add(
      TMP_V3.copy(this.targetPosition)
        .sub(this.position)
        .multiplyScalar(SPRING)
        .multiplyScalar(correction)
    );
    this.position.add(this.positionVelocity.multiplyScalar(DAMPING));

    this.lookAt(this.parent.position);
  }
}
export default AnimatedCamera;

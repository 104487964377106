@import "../../scss/variables.scss";

.root {
  @include toVw(padding, 22);
  @include toVw(border-radius, 20);
  color: white;
  background-color: rgba(#000, 0.6);
  display: inline-block;
  border: 2px solid rgba(#fff, 0.3);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.root--yellow {
  background-color: rgba($colour--yellow, 0.9);
  color: #000;
}

.message {
  @include toVw(font-size, 40, 0.85rem);
  line-height: 1.3em;
  max-width: 50ch;
  margin: 0;
  text-align: left;
}

.message:not(:last-child) {
  @include toVw(margin-bottom, 24);
}

.header {
  @include toVw(font-size, 56, 1.2rem);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.header:not(:last-child) {
  @include toVw(margin-bottom, 24);
}

.icon {
  @include toVw(width, 56, 1.25rem);
  @include toVw(margin-right, 12);
  height: auto;
  flex-shrink: 0;
  margin-top: 0.12em;
  filter: drop-shadow(0px 0px 10px #fff);
}

.root--yellow .icon {
  filter: drop-shadow(0px 0px 10px rgba(#000, 0.5));
}

.root--yellow .icon circle {
  fill: #000;
  stroke: rgba($colour--yellow, 0.9);
}

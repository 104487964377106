import App from "./App";
import ReactDOM from "react-dom";
import { shuffle, debounce } from "lodash";

import resources from "./config/resources.json";
import UIAudio from "./UIAudio";
import AppTakeHome from "./AppTakeHome";
import { decodeInventory, decodeName } from "./util/helpers";

export const searchParams = new URLSearchParams(window.location.search);
export const IS_DEV = searchParams.has("is-dev");

// REBALANCE CODE TEST

// import dummyInventory from "./tmp/dummy-inventory-2.json";
// import {
//   formatChainBalanceSummary,
//   getRebalanceInventoryActions,
//   makeRebalanceIterator,
// } from "./util/helpers";
// import { ResourceType } from "./contexts/types";

// const dummyInventoryBalance = formatChainBalanceSummary(
//   ResourceType.Mountain,
//   dummyInventory.inventory
// );

// const rebalanceInventoryActions = getRebalanceInventoryActions(
//   dummyInventory.inventory,
//   dummyInventoryBalance.summary
// );
// const rebalanceIterator = makeRebalanceIterator(rebalanceInventoryActions);

// const runAction = (value) => {
//   if (value.action === "remove") {
//     console.log("REMOVE", value._id);
//   }

//   if (value.action === "add") {
//     const { quantity: requiredQuantity, type: requiredType } = value;
//     const shuffledResources = shuffle(resources.resources);
//     const foundResource = shuffledResources.find(({ _id, quantity, type }) => {
//       return (
//         requiredType === type &&
//         requiredQuantity === quantity &&
//         !dummyInventory.inventory.find(({ _id: itemId }) => itemId === _id)
//       );
//     });
//     console.log("ADD", foundResource._id);
//   }

//   const next = rebalanceIterator.next();
//   if (!next.done) {
//     setTimeout(() => {
//       runAction(next.value);
//     }, 222);
//   }
// };
// runAction(rebalanceIterator.next().value);

// END REBALANCE CODE TEST

const kickIt: () => void = () => {
  const onResize = () => {
    // if (window.innerWidth <= 1600) {
    //   document.documentElement.style.setProperty("--global-font-size", "0.5em");
    // } else {
    //   document.documentElement.style.setProperty("--global-font-size", "1em");
    // }
  };

  onResize();
  window.addEventListener("resize", debounce(onResize, 111));

  if (process.env.BUILD_MODE === "chain") {
    document.body.classList.add("chain");
    ReactDOM.render(
      <AppTakeHome
        chain={decodeInventory(searchParams.get("c"))}
        name={decodeName(searchParams.get("n"))}
      />,
      document.getElementById("app")
    );
  } else {
    if (
      !searchParams.has("is-dev") &&
      searchParams.get("connectionKey") !== "dev"
    ) {
      document.body.classList.add("experience");
    }

    ReactDOM.render(
      <App
        connectionKey={searchParams.get("connectionKey")}
        connectAsDev={IS_DEV}
      />,
      document.getElementById("app")
    );
  }
};

if (document.addEventListener) {
  document.addEventListener("DOMContentLoaded", kickIt);
} else {
  (window as any).attachEvent("onload", kickIt);
}

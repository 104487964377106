import vertexShader from "./resource-connection.vert";
import fragmentShader from "./resource-connection.frag";
import { Color, DoubleSide, ShaderMaterial } from "three";

export interface Params {
  color: number;
}

class ResourceConnectionMaterial extends ShaderMaterial {
  constructor({ color }: Params) {
    const uniforms = {
      uColour: { value: new Color(color) },
      uDrawProgress: { value: 0.5 },
      uLength: { value: 1 },
      uTravelDist: { value: 0 },
    };

    super({
      uniforms,
      vertexShader,
      fragmentShader,
      linewidth: 1,
      transparent: true,
    });
  }

  public update(delta: number): void {
    this.uniforms.uTime.value += (1 / 60) * delta;
  }
}

export default ResourceConnectionMaterial;

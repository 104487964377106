import audioUiSrcs from "url:./audio/interaction/mp3/*.mp3";
import audioSoundtrackSrcs from "url:./audio/soundtrack/mp3/*.mp3";
import audioChainSrcs from "url:./audio/chain/mp3/*.mp3";
import { Howl, Howler } from "howler/src/howler.core";

const FADE_DURATION_MS = 1000;
const SOUNDTRACK_VOLUME = 0.6;

class UIAudio {
  private sounds: Map<string, Howl> = new Map();
  private currentSoundrack: Howl | null = null;
  private currentChain: string[] | null = null;
  private currentChainIndex = 0;

  constructor(loadUi = true, loadChain = true, loadSoundtrack = true) {
    this.isMuted = false;

    if (loadUi) {
      Object.keys(audioUiSrcs).forEach((key) => {
        this.sounds.set(
          key,
          new Howl({
            src: audioUiSrcs[key],
            loop: false,
            onfade: function () {
              if (this.volume === 0) {
                this.pause();
              }
            },
          })
        );
      });
    }

    if (loadChain) {
      Object.keys(audioChainSrcs).forEach((key) => {
        this.sounds.set(
          key,
          new Howl({
            src: audioChainSrcs[key],
            loop: false,
            onfade: function () {
              if (this.volume === 0) {
                this.pause();
              }
            },
          })
        );
      });
    }

    if (loadSoundtrack) {
      Object.keys(audioSoundtrackSrcs).forEach((key) => {
        this.sounds.set(
          key,
          new Howl({
            src: audioSoundtrackSrcs[key],
            loop: true,
            onfade: function () {
              if (this.volume === 0) {
                this.pause();
              }
            },
          })
        );
      });
    }
  }

  private unsuspendAudio() {}

  public set isMuted(isMuted: boolean) {
    Howler.mute(isMuted);
  }

  public play(key: string, fadeIn: boolean = false): void {
    if (!this.sounds.has(key)) return console.log("no sound for", key);
    const sound = this.sounds.get(key);
    if (fadeIn) {
      sound.fade(0, 1, FADE_DURATION_MS);
    }
    sound.play();
  }

  public playSoundtrack(key: string): void {
    if (!this.sounds.has(key)) return console.warn(`No audio found for ${key}`);
    const soundtrack = this.sounds.get(key);
    if (this.currentSoundrack === soundtrack) return;

    if (this.currentSoundrack) {
      this.currentSoundrack.fade(
        this.currentSoundrack.volume(),
        0,
        FADE_DURATION_MS
      );
    }
    soundtrack.pause();
    soundtrack.volume(0);
    soundtrack.fade(0, SOUNDTRACK_VOLUME, FADE_DURATION_MS);
    soundtrack.play();
    this.currentSoundrack = soundtrack;
  }

  public stop(key: string, fadeOut?: boolean): void {
    if (!this.sounds.has(key)) return;
    const sound = this.sounds.get(key);
    const shouldFade = fadeOut === undefined ? sound.loop : fadeOut;
    if (shouldFade) {
      sound.onfade = () => {
        sound.onfade = null;
        sound.stop();
      };
      sound.fade(1, 0, FADE_DURATION_MS);
    } else {
      sound.stop();
    }
  }

  private onChainItemEnded = () => {
    if (!this.currentChain) return;
    this.currentChainIndex =
      (this.currentChainIndex + 1) % this.currentChain.length;

    this.playChainItemByIndex(this.currentChainIndex);
  };

  private playChainItemByIndex = (index: number) => {
    const sound = this.sounds.get(this.currentChain[index]);
    sound.volume(1);
    sound.once("end", this.onChainItemEnded);
    sound.play();

    const event = new CustomEvent("chain-item-audio", { detail: index });
    window.dispatchEvent(event);
  };

  public playChain(keys: string[]) {
    this.currentChainIndex = 0;
    this.currentChain = keys;

    this.playChainItemByIndex(this.currentChainIndex);
  }

  public stopChain() {
    if (!this.currentChain) return;
    const sound = this.sounds.get(this.currentChain[this.currentChainIndex]);
    sound.fade(1, 0, 500);
    sound.off("end", this.onChainItemEnded);
    this.currentChain = null;
  }
}

export default UIAudio;

import * as classes from "./take-home-canvas.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import ControllerWebGLView from "../../experience/WebGLViewBasic";
import TakeHomeScene from "../../experience/TakeHomeScene";
import { TInventoryItem, TResource } from "../../contexts/types";

const TakeHomeCanvas: FC<{ chain: TResource[] }> = ({ chain }) => {
  const canvasRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const init = async () => {
      const webGlView = new ControllerWebGLView(canvasRef.current);
      await webGlView.init();
      const scene = new TakeHomeScene();
      await scene.init(chain);
      webGlView.setScene(scene);

      setIsLoaded(true);
    };
    init();
  }, []);

  return (
    <div className={classes.root}>
      <canvas ref={canvasRef} className={classes.canvas}></canvas>
      {!isLoaded ? (
        <span className={classes.loading}>Loading your chain...</span>
      ) : null}
    </div>
  );
};

export default TakeHomeCanvas;

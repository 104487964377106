@import "../../scss/variables.scss";

.root {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.2rem;
  @include toVw(padding, 3);
  will-change: transform;
  @include toVw(width, 1200);
  @include generic-css-transition-in-out($translate: false);
}

.header {
  @include toVw(margin-bottom, 42);
  @include toVw(margin-left, -80, -100rem, 999rem, 3840);
}

.inventory {
  @include span-white-border;
}

.verb--add {
  color: $colour--yellow;
}

.verb--remove {
  color: $colour--blue;
}

.inner {
  @include generic-css-transition-in-out($translate: true);
  position: absolute;
  width: 100%;
}
